import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { LoaderService } from '../services/loader.service';

@Injectable({
    providedIn: 'root',
})
export class LoaderInterceptor {
    constructor(
        private router: Router,
        private loaderService: LoaderService
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.loaderService.show();
            } else if (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError
            ) {
                this.loaderService.hide();
            }
        });
    }
}
