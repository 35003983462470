import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceConfiguration, SummaryData } from '../../interfaces/SummaryData';
import { Card } from 'src/app/models/category.model';
import { Router } from '@angular/router';
import { FunnelService } from 'src/app/pages/new-service/services/funnel.service';
import { UserService } from 'src/app/services/user.service';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export enum PersonalizationId {
  WashingHandMade = 'hand-wash',
  WashingSpecialistCenters = 'specialized-center-wash',
  MaintenancePackages = 'maintenance-pack',
  TechnicalInspection = 'technical-inspection',
  PreMot = 'pre-mot',
  PreMot360 = '360-pre-mot',
  MotHomologation = 'mot-homologation',
  Itv = 'mot-fuel-car-es',
  Itv360 = '360-mot-fuel-car-es',
  ItvMotorcycle = 'mot-motorbike-es',
  ItvMotorcycle360 = '360-mot-motorbike-es',
  ItvccMotorcycle = 'mot-motorbike-125cc-es',
  ItvccMotorcycle360 = '360-mot-motorbike-125cc-es',
  TowTruck = 'tow_truck',
  Parking = 'parking',
  Tyres = 'tyres',
  OwnershipChange = 'ownership-change',
  Refuel = 'refueling-car-topping',
  OpenForm = 'open-form',
  Valet = 'valet-station',
  MediumLongDistanceTranfer = 'medium-long-distance',
  Transfer = 'transfer',
  Transit = 'transit',
  TransferReplacementVehicle = 'transfer-replacement-vehicle',
}

@Component({
  selector: 'app-new-service-personalization',
  templateUrl: './new-service-personalization.component.html',
  styleUrls: ['./new-service-personalization.component.scss'],
})
export class NewServicePersonalizationComponent implements OnInit {
  @Output() nextStepEvent = new EventEmitter<void>();
  @Output() previousStepEvent = new EventEmitter<void>();
  @Output() selectedSubCategoryEvent = new EventEmitter<Card>();
  @Output() serviceConfigurationEvent = new EventEmitter<ServiceConfiguration>();
  @Output() changedSummaryDataEvent = new EventEmitter<SummaryData>();
  @Input() categoryId = '';
  @Input() summaryData: SummaryData = {} as SummaryData;
  @Input() productSelectionStepForm: FormGroup = new FormGroup({});
  @Input() preBooking!: (isSimulation: boolean) => void;
  personalizationId = PersonalizationId;
  currentStep = 3;
  tyreRedirectUrl = 'https://neumaticos.cafler.com/';
  parkingRedirectUrl = 'https://www.onepark.co/es?utm_medium=Affiliates&utm_source=cafler';
  isNotConventionalPersonalization = false;
  finalProductSelected = 2;
  steps: string[] = [];

  userEmail: string | undefined;
  userId: string | undefined;

  constructor(
    private router: Router,
    private funnelService: FunnelService,
    private userService: UserService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.steps = [
      this.translate.instant('yourVehicle'),
      this.translate.instant('pickupAndReturn'),
      this.translate.instant('services'),
      this.translate.instant('personalization'),
      this.translate.instant('summary'),
      this.translate.instant('extras'),
    ];
    if (
      this.summaryData.productInformation &&
      !this.summaryData.productInformation.product?.IsBundle &&
      !this.summaryData.productInformation.product?.needsPersonalization
    ) {
      this.currentStep = 5;
      this.router.navigate(['new-service', { step: 5 }]);
    }

    this.handleIncomingCategoryId();

    this.userService.getUserInfo().subscribe((user) => {
      this.userEmail = user.email;
      this.userId = user.id;
    });
  }

  hasSubProducts(): boolean {
    const product = this.summaryData.productInformation?.product;
    return !!(product && product.RelatedProducts && product.RelatedProducts.length > 0);
  }

  handleSelectedSubCategory(card: any): void {
    this.productSelectionStepForm.patchValue({
      id: card.Id,
      title: card.title,
      icon: card.icon,
      price: card.Price,
      caflerFee: card.CaflerFee,
      addedNonScheduledMotPrice: card.addedNonScheduledMotPrice,
      productKey: card.ProductKey,
      verticalType: card.VerticalType,
      selectedSubcategory: true,
      needsPersonalization: true,
      personalizationDescription: card.PersonalizationDescription,
    });
    this.selectedSubCategoryEvent.emit(card);
  }

  handleIncomingCategoryId(): void {
    switch (this.summaryData.productInformation.product?.ProductKey) {
      //   case this.personalizationId.Tyres:
      //     window.open(this.tyreRedirectUrl, '_blank');
      //     this.isNotConventionalPersonalization = true;
      //     this.currentStep = 3;
      //     this.router.navigate(['new-service', { step: 3 }]);
      //     break;
      case this.personalizationId.Parking:
        window.open(this.parkingRedirectUrl, '_blank');
        this.isNotConventionalPersonalization = true;
        this.currentStep = 3;
        this.router.navigate(['new-service', { step: 3 }]);
        break;
      case this.personalizationId.Tyres:
      case this.personalizationId.TowTruck:
      case this.personalizationId.OwnershipChange:
      case this.personalizationId.Refuel:
      case this.personalizationId.OpenForm:
        this.isNotConventionalPersonalization = true;
        break;
      case this.personalizationId.TechnicalInspection:
        if (this.summaryData.serviceConfiguration) {
          this.summaryData.serviceConfiguration.RequiresTechnicalInspection = false;
          this.summaryData.serviceConfiguration.requiresPreTechnicalInspection = false;
        }
        this.isNotConventionalPersonalization = true;
        break;
      default:
        break;
    }
  }

  collectDataAndProceed(): void {
    if (this.userEmail && this.summaryData.locationInfo) {
      //TODO: Implementar el método correspondiente para cada tipo de producto
      this.funnelService.bookTechnicalInspection(this.summaryData, this.userEmail);
      this.nextStepEvent.emit();
    } else {
      console.error('User email or location info is missing');
    }
  }

  back(): void {
    this.previousStepEvent.emit();
  }
  onChangeSummaryData(event: SummaryData) {
    this.changedSummaryDataEvent.emit(event);
  }

  onRefillSelectionChange(event: { monetaryAmount?: number; fuelType?: number }): void {
    console.log('Refill Selection Change Event:', event);
    const serviceConfiguration = {
      monetaryAmount: event.monetaryAmount,
      fuelType: event.fuelType,
    };
    this.serviceConfigurationEvent.emit(serviceConfiguration);
  }

  onTechnicalInspectionChange(event: any): void {
    const serviceConfiguration = {
      appointmentCode: event.appointmentCode ?? '',
      hasAppointment: event.hasAppointment ?? false,
      IsInspectionPaid: event.IsInspectionPaid ?? false,
      stationName: event.station?.Name ?? '',
      stationId: event.station?.Id ?? '',
      providerAddress: event.providerAddress ?? '',
      providerLongitude: event.providerLongitude ?? '',
      providerLatitude: event.providerLatitude ?? '',
      providerDate: event.providerDate ?? '',
      providerHour: event.providerHour ?? '',
      appointmentTime: event.appointmentTime,
      appointmentDate: event.appointmentDate,
      appointmentHour: event.appointmentHour,
      hasCompletedRegularInspections: event.hasCompletedRegularInspections ?? false,
      areDocumentsAvailable: event.areDocumentsAvailable ?? false,
      reformType: event.reformType ?? 1,
      RequiresTechnicalInspection: event.requiresTechnicalInspection ?? false,
    };
    this.serviceConfigurationEvent.emit(serviceConfiguration);
  }

  onVehicleMovementStatus(event: any): void {
    const serviceConfiguration = {
      vehicleMovementStatus: event,
    };
    this.serviceConfigurationEvent.emit(serviceConfiguration);
    this.handleSelectedSubCategory(this.summaryData.productInformation.product);
  }
}
