<div class="h-100">
    <div
        *ngIf="markers.length > 0 || isPickUpLocation; else noMarkersTemplate"
        id="map"
        [ngClass]="{
            'map-home': isHome,
            'map-active-routes': isActiveRoutes,
            'map-pickup-location': isPickUpLocation
        }"
    ></div>

    <ng-template #noMarkersTemplate>
        <div *ngIf="isHome; else emptyMap">
            <div class="d-flex flex-column align-items-center w-100 no-map-content">
                <div class="empty-home-page d-flex flex-column align-items-center">
                    <img src="../../../assets/empty-home-map.svg" alt="Empty home page" />
                    <h4>{{ 'noVehiclesOut' | translate }}</h4>
                    <p class="text-center">{{ 'activeServiceStatus' | translate }}</p>
                </div>
            </div>
        </div>

        <ng-template #emptyMap>
            <div class="d-flex flex-column align-items-center w-100">
                <div
                    id="map"
                    class="map-empty"
                    [ngClass]="{
                        'map-home': isHome,
                        'map-active-routes': isActiveRoutes,
                        'map-pickup-location': isPickUpLocation
                    }"
                >
                    <img src="../../../assets/basemap.svg" alt="Map" />
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>
<!-- Modal -->
<div
    class="modal fade"
    id="step2AddressModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="step2AddressModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content contact-modal p-4">
            <div class="w-100 d-flex justify-content-end">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="content">
                <div class="contact-box">
                    <h5 id="step2AddressModalLabel">{{ 'completeValidAddress' | translate }}</h5>
                    <p [innerHTML]="'completeValidAddressDescription' | translate"></p>
                    <button class="btn btn-dark btn-lg btn-block w-100" data-bs-dismiss="modal">
                        {{ 'understood' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
