<div class="w-100 d-flex flex-column justify-content-center p-2 gap-10">
    <h2 mat-dialog-title class="confirm-dialog-title w-100 d-flex justify-content-center">
        {{ data.title | translate }}
    </h2>
    <mat-dialog-content class="w-100 d-flex justify-content-center">{{ data.message | translate }}</mat-dialog-content>
    <mat-dialog-actions class="d-flex w-100 gap-20">
        <button mat-button [mat-dialog-close]="false" class="cancel-button">{{ data.cancelText | translate }}</button>
        <button mat-button [mat-dialog-close]="true" class="confirm-button">
            {{ data.confirmText | translate }}
        </button>
    </mat-dialog-actions>
</div>
