import { Injectable } from '@angular/core';
import { Status } from '../../status/enum/Status.enum';
import { MyServicesService } from 'src/app/pages/my-services/services/my-services.service';

@Injectable({
  providedIn: 'root',
})
export class DatatableService {
  constructor(private myServicesRequest: MyServicesService) {}

  cancelService(service: string, status: number): void {
    if (Status.Initialized === status || Status.Pending === status || Status.Confirmed === status) {
      this.myServicesRequest.cancelService(service).subscribe(
        (data) => {
          window.location.reload();
        },
        (error) => {
          console.error('Error al cancelar el servicio', error);
        }
      );
    }
  }
}
