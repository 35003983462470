import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DatatableService } from '../../services/datatable.service';

@Component({
  selector: 'app-address-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss'],
})
export class CancelModalComponent implements OnInit {
  constructor(
    private router: Router,
    private dataService: DatatableService,
    public bsModalRef: BsModalRef
  ) {}

  headerText: string | undefined;
  routesConfig = this.router.config;
  orderHash!: string;
  status!: number;

  async ngOnInit() {
    this.headerText = this.routesConfig.find(
      (e) => e.path === this.router.url.split('/')[1]
    )?.data?.['title'];
  }

  cancelService(): void {
    this.dataService.cancelService(this.orderHash, this.status);
    this.bsModalRef.hide();
  }

  onClose(): void {
    this.bsModalRef.hide();
  }
}
