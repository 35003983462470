<div class="checkout-container" *ngIf="shouldShowCheckoutContainer()">
  <!-- Total -->
  <div class="d-flex justify-content-between billing-summary title" *ngIf="shouldShowBillingSummary()">
    <span>{{ 'total' | translate }}</span>
    <span>{{ getDisplayedTotalValue() }}{{ 'currency_symbol' | translate }}</span>
  </div>

  <!-- Código Promocional -->
  <ng-container *ngIf="currentStep === 6 || currentStep === 7">
    <div class="promotion-code-box" *ngIf="!isOutOfRange()">
      <button class="promotion-code-button" data-bs-toggle="modal" data-bs-target="#modalPromotionCode">
        <span>{{ 'promo_code_title' | translate }}</span>
        <img src="/assets/icons/chevron-right.svg" alt="Arrow right" />
      </button>
      <div *ngIf="shouldShowCoupon() && summaryData.Coupons" class="w-100">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="promotion-code-text-summary">{{
            summaryData.Coupons[0].CouponInformation.Code
            }}</span>
          <button class="promotion-code-delete" (click)="onDeletePromoCode()">
            {{ 'delete' | translate }}
          </button>
        </div>
      </div>
    </div>

    <!-- Checkboxes de términos y condiciones -->
    <div class="contenedor-checkbox">
      <div class="d-flex">
        <input type="checkbox" id="minimumConditions" [(ngModel)]="minimumConditions" (change)="updateButtonState()" />
        <label for="minimumConditions">
          {{ 'minimumConditions-1' | translate }}
          <button data-bs-toggle="modal" data-bs-target="#modalMinimumConditions">
            {{ 'minimumConditions-2' | translate }}
          </button>
          {{ 'minimumConditions-3' | translate }}
        </label>
      </div>
      <div class="d-flex">
        <input type="checkbox" id="termsAndConditions" [(ngModel)]="termsAndConditions"
          (change)="updateButtonState()" />
        <label for="termsAndConditions">
          {{ 'termsAndConditions-1' | translate }}
          <a href="https://cafler.com/es/terminos-y-condiciones/" target="_blank">{{
            'termsAndConditions-2' | translate
            }}</a>
        </label>
      </div>
    </div>
  </ng-container>



  <!-- Botón de Reservar -->
  <button class="btn btn-primary btn-lg btn-block" (click)="onButtonClick()"
    *ngIf="(currentStep === 6 || currentStep === 4 || currentStep === 7)" [disabled]="
      (disabledButtonLastStep && currentStep === 6) ||
      (currentStep === 4 && !summaryData.productInformation.product?.selectedSubcategory)
    ">
    <span>{{ buttonText }}</span>
    <img *ngIf="!summaryData.productInformation.product?.outOfRange" src="/assets/icons/arrow-sm-right.svg"
      alt="Arrow right" />
  </button>


</div>