<div class="pricing-summary-content billing-summary" *ngIf="shouldShowPricingSummary()">
    <span class="title">{{ 'price_breakdown' | translate }}</span>
    <div class="spacing-billing-summary d-flex flex-column">
        <!-- Producto principal -->
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex service-type align-items-center">
                <img
                    *ngIf="!isOutOfRange()"
                    [src]="summaryData.productInformation.icon || '/assets/icons/information-circle.svg'"
                    alt="Info icon"
                    class="icon-container"
                />
                <img
                    *ngIf="isOutOfRange()"
                    [src]="
                        summaryData.productInformation.product?.outOfRangeIcon || '/assets/icons/information-circle.svg'
                    "
                    alt="Info icon"
                    class="icon-container"
                />
                <span>{{ getProductTitle() }}</span>
            </div>
            <span class="price">{{ getProductPrice() }}</span>
        </div>
        <div
            class="d-flex justify-content-between align-items-center"
            *ngIf="
                summaryData.serviceConfiguration?.RequiresTechnicalInspection &&
                summaryData.productInformation.product?.addedNonScheduledMotPrice
            "
        >
            <div class="d-flex service-type align-items-center">
                <img [src]="'/assets/service-icons/itv-españa.svg'" alt="Info icon" class="icon-container" />
                <span>{{ 'non-scheduled-mot' | translate }}</span>
            </div>
            <span class="price"
                >{{ summaryData.productInformation.product?.addedNonScheduledMotPrice
                }}{{ 'currency_symbol' | translate }}</span
            >
        </div>

        <ng-container *ngIf="currentStep === 6 || currentStep === 7">
            <div *ngFor="let topping of summaryData.toppingInformation" class="d-flex flex-column">
                <div>
                    <div class="d-flex service-type justify-content-between">
                        <div class="d-flex">
                            <img [src]="topping.icon" alt="Topping icon" class="service-icon" />
                            <span class="service-name">
                                {{
                                    isRefueling(topping.description ?? '')
                                        ? summaryData.vehicleInformation.fuelType
                                        : (topping.title ?? '' | translate)
                                }}
                            </span>
                        </div>
                        <span class="price"> {{ topping.price }}{{ 'currency_symbol' | translate }} </span>
                    </div>
                </div>
                <div class="exent-supplement exent-supplement-extra">
                    <span *ngIf="isRefueling(topping.title!)">
                        {{ 'exentSupplement' | translate }}
                    </span>
                </div>
            </div></ng-container
        >
        <hr class="divider" />

        <!-- Tasas y cargos adicionales -->

        <ng-container *ngIf="summaryData.productInformation.product?.CaflerFee">
            <div class="d-flex justify-content-between align-items-center">
                <span>{{ getCaflerFeeLabel() }}</span>
                <span class="price"
                    >{{ summaryData.productInformation.product?.CaflerFee }}{{ 'currency_symbol' | translate }}</span
                >
            </div>
        </ng-container>

        <div class="d-flex justify-content-between align-items-center" *ngIf="summaryData.FixedFee">
            <span>{{ 'fixedFee' | translate }}</span>
            <span class="price">{{ summaryData.FixedFee }}{{ 'currency_symbol' | translate }}</span>
        </div>

        <div class="d-flex justify-content-between align-items-center" *ngIf="summaryData.urgencyFee">
            <span>{{ 'urgency_fee' | translate }}</span>
            <span class="price">{{ summaryData.urgencyFee }}{{ 'currency_symbol' | translate }}</span>
        </div>

        <div
            class="d-flex justify-content-between align-items-center"
            *ngIf="summaryData.refuelingAdditionalFee && summaryData.refuelingAdditionalFee > 0"
        >
            <span>{{ 'refuelingAdditionalFee' | translate }}</span>
            <img
                src="/assets/icons/information-circle.svg"
                alt="Info icon"
                class="icon-size-small align-bottom"
                data-bs-toggle="modal"
                data-bs-target="#tasaRepostajeModal"
            />
            <span class="price">{{ summaryData.refuelingAdditionalFee }}{{ 'currency_symbol' | translate }}</span>
        </div>

        <!-- IVA -->
        <div class="d-flex justify-content-between align-items-center" *ngIf="summaryData.TotalOrderTaxAmount">
            <span>{{ 'vat' | translate }}</span>
            <span class="price">{{ summaryData.TotalOrderTaxAmount }}{{ 'currency_symbol' | translate }}</span>
        </div>

        <!-- Total -->
        <div class="d-flex justify-content-between billing-summary title" *ngIf="isFunnelDialog || isTransit()">
            <span>{{ 'total' | translate }}</span>
            <span>{{ summaryData.TotalOrderAmount }}{{ 'currency_symbol' | translate }}</span>
        </div>

        <!-- Código promocional -->
        <ng-container *ngIf="!summaryData.productInformation.product?.outOfRange"
            ><ng-container *ngIf="promotionCode && !promotionCodeValidator">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="promotion-code-text">{{ promotionCode }}</span>
                    <span class="price">-{{ promotionCodeValue }} {{ 'currency_symbol' | translate }}</span>
                </div>
            </ng-container></ng-container
        >
    </div>
</div>
