<ul [ngClass]="{ 'stepper-list': !isVertical, 'vertical-stepper-list d-flex justify-content-around': isVertical }">
    <li
        *ngFor="let step of steps; let i = index"
        [ngClass]="{ pointer: i < activeStep }"
        [class.active]="i === activeStep"
        [class.completed]="i < activeStep"
        (click)="redirectToStep(i)"
        (keyup)="redirectToStep(i)"
        tabindex="0"
    >
        <img *ngIf="i < activeStep" src="/assets/icons/completed-step.svg" alt="Completed" class="step-icon" />
        <div *ngIf="i >= activeStep" class="step-circle" [class.active]="i === activeStep"></div>
        {{ step | translate }}
    </li>
</ul>
