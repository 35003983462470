import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
    constructor(
        private authService: MsalService,
        private router: Router,
        private loader: LoaderService
    ) {}

    ngOnInit() {
        this.loader.show();
        this.authService.logout().subscribe(() => {
            this.router.navigate(['/login']).then(() => {
                this.loader.hide();
            });
        });
    }
}
