import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoaderService } from 'src/app/core/services/loader.service';
import { SummaryData, ToppingInformation } from 'src/app/pages/new-service/interfaces/SummaryData';
import { MappedTopping } from 'src/app/pages/new-service/interfaces/Topping';
import { FunnelService } from 'src/app/pages/new-service/services/funnel.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-modal/confirm-dialog.component';

export interface InsuranceOptions {
    Days: number;
    Price: number;
}

@Component({
    selector: 'app-modal-extra-insurance-vehicle',
    templateUrl: './modal-extra-insurance-vehicle.component.html',
    styleUrls: ['./modal-extra-insurance-vehicle.component.scss'],
})
export class ModalExtraInsuranceVehicleComponent implements OnInit {
    @ViewChild('refillBox') refillBox!: ElementRef;
    @ViewChild('alertComponent') alertComponent!: ElementRef;
    insuranceForm: FormGroup;
    selected = 0;
    options: InsuranceOptions[] = [];
    summaryData: SummaryData;
    insuranceTopping: MappedTopping;
    invalidForm = false;
    showErrorAlert = false;
    errorAlertText = '';

    constructor(
        private fb: FormBuilder,
        private funnelService: FunnelService,
        private loader: LoaderService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<ModalExtraInsuranceVehicleComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { summaryData: SummaryData; mappedTopping: MappedTopping }
    ) {
        this.summaryData = data.summaryData;
        this.insuranceTopping = data.mappedTopping;
        this.insuranceForm = this.fb.group({
            nombre: ['', Validators.required],
            apellidos: ['', Validators.required],
            tipo: ['dni', Validators.required],
            numero: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.loadInsuranceOptions();
    }

    private loadInsuranceOptions(): void {
        this.funnelService
            .getInsuranceOptions(this.summaryData.productInformation.ZoneId as string)
            .subscribe((options: InsuranceOptions[]) => {
                this.options = options;
            });
    }

    selectOption(option: number): void {
        this.selected = option;
    }

    onClose(): void {
        const confirmationDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'sure_you_wanna_leave',
                message: 'disclousure_message_confirmation_dialog',
                confirmText: 'confirm',
                cancelText: 'cancel',
            },
        });

        confirmationDialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.closeMainDialog();
            }
        });
    }

    private closeMainDialog(): void {
        this.dialogRef.close({ incompletedTopping: true });
    }

    private getServiceConfiguration(): any {
        const formValue = this.insuranceForm.value;
        return {
            Brand: this.summaryData.vehicleInformation.brand,
            Model: this.summaryData.vehicleInformation.model,
            LicensePlate: this.summaryData.vehicleInformation.licensePlate,
            OwnerFirstName: formValue.nombre,
            OwnerLastName: formValue.apellidos,
            DurationAmount: this.options[this.selected].Days,
            DurationType: 0,
            DocumentNumber: formValue.numero,
            DocumentType: formValue.tipo === 'dni' ? 1 : formValue.tipo === 'nie' ? 2 : 3,
            VehicleRegistrationCountry: 'ES',
        };
    }

    private getAddedTopping(): ToppingInformation {
        return {
            title: 'insurance',
            serviceConfiguration: {
                monetaryAmount: this.options[this.selected].Price,
            },
            price: this.options[this.selected].Price,
            quantity: this.options[this.selected].Days,
            icon: 'assets/icons/insurance-during-service.svg',
        };
    }

    addTopping() {
        if (this.insuranceForm.valid) {
            this.loader.show();
            this.funnelService
                .addTopping(
                    this.insuranceTopping.products[0].id,
                    this.summaryData.orderHash as string,
                    this.getServiceConfiguration(),
                    'replacement-vehicle'
                )
                .subscribe({
                    next: (response: any) => {
                        this.loader.hide();
                        if (response.Errors && response.Errors.length > 0) {
                            this.showErrorAlert = true;
                            this.errorAlertText = 'No se pudo añadir el topping. Por favor, inténtelo de nuevo.';
                            this.scrollToTop();
                            return;
                        }
                        this.dialogRef.close(response);
                    },
                    error: (error) => {
                        this.loader.hide();
                        this.showErrorAlert = true;
                        this.errorAlertText = 'No se pudo añadir el topping. Por favor, inténtelo de nuevo.';
                        console.error('Error añadiendo topping:', error);
                        this.scrollToTop();
                    },
                });
        } else {
            this.invalidForm = true;
        }
    }
    onAlertClosed(): void {
        this.showErrorAlert = false;
    }
    scrollToTop() {
        setTimeout(() => {
            if (this.refillBox && this.alertComponent) {
                this.refillBox.nativeElement.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });

                // Añadir una clase para la animación de resaltado
                this.alertComponent.nativeElement.classList.add('highlight');

                // Remover la clase después de la animación
                setTimeout(() => {
                    this.alertComponent.nativeElement.classList.remove('highlight');
                }, 1500); // Duración de la animación
            }
        });
    }
}
