import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-plate-number',
    templateUrl: './plate-number.component.html',
    styleUrls: ['./plate-number.component.scss'],
})
export class PlateNumberComponent {
    @Input() plateValue = '';
}
