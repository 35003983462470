<div
    class="modal fade"
    id="modalMinimumConditions"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalTitleId"
    aria-hidden="true"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="contenido-modal">
                <div class="w-100 d-flex justify-content-end">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="title-modal">
                    <h3>{{ 'minimumRequirementsTitle' | translate }}</h3>
                    <span>{{ 'minimumRequirementsDescription' | translate }}</span>
                </div>
                <div class="images">
                    <div *ngFor="let requirement of minimumRequirements; let i = index">
                        <img [src]="requirement.icon" [alt]="'Requirement ' + (i + 1)" />
                        <span>{{ requirement.text | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
