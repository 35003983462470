<div class="address-box">
    <div class="address-box-img">
        <img src="../../../../../assets/icons/gps-icon.svg" alt="gps icon" class="location-icon" />
        <img src="../../../../../assets/icons/active-routes-adress-line.svg" alt="address Line" class="line-icon" />
        <img src="../../../../../assets/icons/gps-icon.svg" alt="gps icon" class="location-icon" />
    </div>
    <div class="address-box-text">
        <div>
            <span class="address-headers">{{ 'pickup' | translate }}</span>
            <div class="d-flex flex-column location-info-container">
                <span class="address-styles">
                    {{ summaryData.locationInfo?.originAddress }}
                    {{ summaryData.locationInfo?.originAddressDetails }}
                </span>
                <span *ngIf="!routeOnly">{{ handleStartDate() }}</span>
                <span *ngIf="!routeOnly">{{ handleStartTime() }}</span>
            </div>
        </div>
        <div>
            <span class="address-headers mt-4">{{ 'delivery' | translate }}</span>
            <div class="d-flex flex-column location-info-container">
                <span class="address-styles">
                    {{ summaryData.locationInfo?.destinationAddress }}
                    {{ summaryData.locationInfo?.destinationAddressDetails }}
                </span>
                <span>{{ summaryData.locationInfo?.formattedReturnDate }}</span>
                <span *ngIf="summaryData.locationInfo?.formattedReturnEndTime">
                    {{ summaryData.locationInfo?.formattedReturnStartTime }} -
                    {{ summaryData.locationInfo?.formattedReturnEndTime }}
                </span>
            </div>
        </div>
    </div>
</div>
