<div class="modal-container">
    <div class="modal-exit">
        <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
    </div>
    <div class="info-content" *ngIf="productInfo">
        <div class="modal-info">
            <div class="modal-title-subtitle">
                <div class="modal-title">
                    <img *ngIf="productInfo.productIcon" [src]="productInfo.productIcon" alt="product-icon" />
                    {{ productInfo.title | translate }}
                </div>
                <div *ngIf="productInfo.description" class="modal-subtitle">
                    {{ productInfo.description | translate }}
                </div>
                <div
                    *ngIf="productInfo.aditionalInformation"
                    class="aditional-information"
                    [innerHTML]="productInfo.aditionalInformation | translate"
                ></div>
                <div
                    *ngIf="productInfo.tinyAditionalInformation"
                    class="tiny-aditional-information"
                    [innerHTML]="productInfo.tinyAditionalInformation | translate"
                ></div>
            </div>
            <div class="includes" *ngIf="productInfo.isIncluded">
                <div class="includes-title">{{ 'what_does_it_include' | translate }}</div>
                <div
                    class="includes-description"
                    *ngIf="!productInfo.includingDescriptionsListed"
                    [innerHTML]="combinedIncludingDescriptions"
                ></div>
                <div *ngIf="productInfo.includingDescriptionsListed">
                    <li *ngFor="let item of productInfo.includingDescriptions">{{ item | translate }}</li>
                </div>
            </div>
            <div class="how-it-works" *ngIf="productInfo.howItWorksDescription || productInfo.howItWorksPlain">
                <div class="how-it-works-title">{{ 'how_does_it_work' | translate }}</div>
                <div
                    class="how-it-works-description"
                    *ngIf="productInfo.howItWorksDescription && productInfo.howItWorksIcon"
                >
                    <div *ngFor="let item of productInfo.howItWorksDescription; let i = index" class="HIT-item">
                        <div>
                            <img [src]="productInfo.howItWorksIcon[i]" alt="product-image" />
                        </div>
                        <div class="HIT-description" [innerHTML]="item | translate"></div>
                    </div>
                </div>
                <div class="how-it-works-plain" *ngIf="productInfo.howItWorksPlain">
                    <div class="HIT-plain-description">{{ productInfo.howItWorksPlain | translate }}</div>
                    <div class="HIT-plain-description-strong" *ngIf="productInfo.howItWorksDescriptionStrong">
                        {{ productInfo.howItWorksDescriptionStrong | translate }}
                    </div>
                </div>
            </div>

            <div *ngIf="productInfo.conditions" class="conditions-container d-flex flex-column">
                <div class="condition-header">{{ 'conditions_summary' | translate }}</div>
                <li *ngFor="let condition of productInfo.conditions" calss="d-flex flex-row conditions-box">
                    <span class="condition-title">{{ condition.title | translate }}</span
                    >:
                    <span class="condition-content" [innerHTML]="condition.content | translate"></span>
                </li>
            </div>
        </div>
        <div class="vertical-hr"></div>
        <div class="modal-request-agrees">
            <img *ngIf="productInfo.productImage" [src]="productInfo.productImage" alt="product-image" />
            <div *ngIf="productInfo.isRequested" class="request">
                <div class="request-title">{{ 'prerequisites' | translate }}</div>
                <div class="request-frame">
                    <div *ngFor="let item of productInfo.requestedDescription; let i = index" class="HIT-item">
                        <div>
                            <img
                                *ngIf="productInfo.requestedIcon"
                                [src]="productInfo.requestedIcon[i]"
                                alt="product-image"
                                class="d-flex"
                            />
                        </div>
                        <div class="request-description" [innerHTML]="item | translate"></div>
                    </div>
                </div>
            </div>
            <div class="agrees">
                <div class="mapfre" *ngIf="!productInfo.isAxa">
                    <img src="../../../assets/icons/mapfre.svg" alt="mapfre icon" />
                    <div class="mapfre-info">
                        <div class="mapfre-title">{{ 'breathe_easy' | translate }}</div>
                        <div class="mapfre-sub">
                            {{ 'your_car_with_additional_insurance' | translate }}
                        </div>
                    </div>
                </div>
                <div class="mapfre" *ngIf="productInfo.isAxa">
                    <img src="../../../assets/icons/info-modal/insurance-topping/axa-logo.png" alt="mapfre icon" />
                    <div class="mapfre-info">
                        <div class="mapfre-title">{{ 'breathe_easy' | translate }}</div>
                        <div class="mapfre-sub">
                            {{ 'your_car_with_additional_insurance_axa' | translate }}
                        </div>
                    </div>
                </div>
                <div class="conditions">
                    {{ 'you_can_cancel_the_reservation' | translate }}
                    {{ 'for_more_information' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
