import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
    ServiceConfiguration,
    SummaryData,
    ToppingInformation,
} from 'src/app/pages/new-service/interfaces/SummaryData';
import { FuelTypeOption } from '../../../new-service-personalization/components/refill/refill.component';
import { FunnelService } from 'src/app/pages/new-service/services/funnel.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-modal/confirm-dialog.component';
import { MappedTopping } from 'src/app/pages/new-service/interfaces/Topping';

@Component({
    selector: 'app-modal-extra-refill',
    templateUrl: './modal-extra-refill.component.html',
    styleUrls: ['./modal-extra-refill.component.scss'],
})
export class ModalExtraRefillComponent implements OnInit {
    selectedMonetaryAmount = 1;
    selectedFuelType = 0;
    fuelTypeOptions: FuelTypeOption[] = [];
    refillForm: FormGroup;
    summaryData: SummaryData;
    refillTopping: MappedTopping;
    invalidForm = false;
    showErrorAlert = false;

    monetaryAmounts = [10, 20, 50, 80];
    errorAlertText = '';

    constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        private loader: LoaderService,
        private funnelService: FunnelService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<ModalExtraRefillComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { summaryData: SummaryData; mappedTopping: MappedTopping }
    ) {
        this.summaryData = data.summaryData;
        this.refillTopping = data.mappedTopping;
        this.refillForm = this.fb.group({
            monetaryAmount: [0, Validators.required],
            fuelType: [1, Validators.required],
        });
    }

    ngOnInit(): void {
        this.handleFuelTypeSummaryData();
        this.selectMonetaryAmount(this.selectedMonetaryAmount);
    }

    onClose(): void {
        const confirmationDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'sure_you_wanna_leave',
                message: 'disclousure_message_confirmation_dialog',
                confirmText: 'confirm',
                cancelText: 'cancel',
            },
        });

        confirmationDialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.closeMainDialog();
            }
        });
    }

    private closeMainDialog(): void {
        this.dialogRef.close({ incompletedTopping: true });
    }

    getServiceConfiguration(): ServiceConfiguration {
        return {
            monetaryAmount: this.refillForm.get('monetaryAmount')?.value,
            fuelType: this.refillForm.get('fuelType')?.value,
        };
    }

    getAddedTopping(): ToppingInformation {
        return {
            title: 'refueling',
            description: 'refueling',
            price: this.refillForm.get('monetaryAmount')?.value,
            quantity: this.selectedMonetaryAmount,
            icon: 'assets/icons/refuel.svg',
        };
    }

    handleFuelTypeSummaryData(): void {
        const fuelTypeId = this.summaryData.vehicleInformation?.fuelTypeId;
        switch (fuelTypeId) {
            case 1:
            case 2:
                this.fuelTypeOptions = [
                    { description: 'fuel95', fuelTypeId: 1, optionId: 1 },
                    { description: 'fuel98', fuelTypeId: 1, optionId: 2 },
                ];
                break;
            case 3:
            case 8:
                this.fuelTypeOptions = [
                    { description: 'dieselPremium', fuelTypeId: 3, optionId: 4 },
                    { description: 'fuel95E10', fuelTypeId: 3, optionId: 8, premium: true },
                ];
                break;
            case 5:
            case 7:
                this.fuelTypeOptions = [
                    { description: 'fuel95', fuelTypeId: 1, optionId: 1 },
                    { description: 'fuel98', fuelTypeId: 1, optionId: 2 },
                    { description: 'dieselPremium', fuelTypeId: 3, optionId: 4 },
                    { description: 'fuel95E10', fuelTypeId: 3, optionId: 8, premium: true },
                ];
                break;
            default:
                this.fuelTypeOptions = [];
        }
    }

    selectMonetaryAmount(option: number): void {
        if (option >= 1 && option <= this.monetaryAmounts.length) {
            this.selectedMonetaryAmount = option;
            this.refillForm.get('monetaryAmount')?.setValue(this.monetaryAmounts[option - 1]);
        }
        this.updateSummaryData();
    }

    selectFuelType(fuelTypeOption: FuelTypeOption): void {
        this.selectedFuelType = fuelTypeOption.optionId;
        this.refillForm.get('fuelType')?.setValue(this.selectedFuelType);
        this.summaryData.vehicleInformation.fuelType = this.translate.instant(fuelTypeOption.description);
        this.updateSummaryData();
    }

    private updateSummaryData(): void {
        if (!this.summaryData.serviceConfiguration) {
            this.summaryData.serviceConfiguration = {};
        }
        this.summaryData.serviceConfiguration.fuelType =
            this.refillForm.get('fuelType')?.value ?? this.summaryData.vehicleInformation.fuelType;
        this.summaryData.serviceConfiguration.monetaryAmount = this.refillForm.get('monetaryAmount')?.value;
    }
    addTopping() {
        if (this.refillForm.valid) {
            this.loader.show();
            this.funnelService
                .addTopping(
                    this.refillTopping.products[0].id,
                    this.summaryData.orderHash as string,
                    this.getServiceConfiguration(),
                    'refill'
                )
                .subscribe({
                    next: (response) => {
                        this.loader.hide();
                        this.dialogRef.close(response);
                    },
                    error: (error) => {
                        this.loader.hide();
                        this.showErrorAlert = true;
                        this.errorAlertText = 'No se pudo añadir el topping. Por favor, inténtelo de nuevo.';
                        console.error('Error añadiendo topping:', error);
                    },
                });
        } else {
            this.invalidForm = true;
        }
    }
    onAlertClosed(): void {
        this.showErrorAlert = false;
    }
}
