import {
  Component,
  EventEmitter,
  Inject,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  HoursAvailabilityResponse,
  ConcatenatedHour,
  Hour,
} from 'src/app/pages/new-service/interfaces/Hour';
import { LocationInfo, SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';
import { SharedFunnelService } from '../functions/shared-functions';
import { ConfirmDialogComponent } from '../confirm-modal/confirm-dialog.component';
import { FunnelService } from 'src/app/pages/new-service/services/funnel.service';
import { UserService } from 'src/app/services/user.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { map, Observable, tap } from 'rxjs';
import {
  MappedProduct,
  MappedTopping,
  ServerProduct,
  ServerTopping,
} from 'src/app/pages/new-service/interfaces/Topping';
import { myToppingDictionary } from 'src/app/pages/new-service/constants/ToppingDictionary';
import { AlertService } from 'src/app/core/services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-funnel-dialog',
  templateUrl: './funnel-dialog.component.html',
  styleUrls: ['./funnel-dialog.component.scss'],
})
export class FunnelDialogComponent implements OnInit, OnChanges {
  @Output() summaryDataUpdated = new EventEmitter<SummaryData>();
  userEmail = '';
  text = '';
  maxChars = 500;
  form: FormGroup;
  swappedSummaryData: SummaryData;
  public isSpecificHours = false;
  public isReturnSpecificHours = false;
  public pickupDateSelected = false;
  selectedDate: Date = new Date();
  selectedStartHour: HoursAvailabilityResponse | null = null;
  selectedReturnStartHour = '';
  selectedEndHour = '';
  selectedReturnEndHour = '';
  selectedSpecificHour = '';
  selectedReturnSpecificHour = '';
  formattedDateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };
  selectedHourPair: ConcatenatedHour | null = null;
  selectedReturnHourPair: ConcatenatedHour | null = null;
  showDropdown = false;
  availableHours: ConcatenatedHour[] = [];
  availableReturnHours: ConcatenatedHour[] = [];
  availableStartHours: HoursAvailabilityResponse[] = [];
  availableReturnStartHours: HoursAvailabilityResponse[] = [];
  availableEndHours: HoursAvailabilityResponse[] = [];
  availableReturnEndHours: HoursAvailabilityResponse[] = [];
  noAvailableHours = false;
  noAvailableEndHours = false;
  warningMessage: any;
  isFromMyServicesSteps = ['data', 'extras', 'summary'];
  currentIsFromMyServicesStep = 0;
  mappedToppings: MappedTopping[] = [];
  minDate: Date = new Date();

  constructor(
    private dialogRef: MatDialogRef<FunnelDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { summaryData: SummaryData; contactForm?: FormGroup; isFromMyServices?: boolean },
    private sharedFunnelService: SharedFunnelService,
    private funnelService: FunnelService,
    private userService: UserService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private loader: LoaderService,
    private router: Router
  ) {
    this.form = this.fb.group({
      extraInfo: ['', [Validators.maxLength(500)]],
    });
    this.swappedSummaryData = {
      ...data.summaryData,
      productInformation: {
        ...data.summaryData.productInformation,
        product: {
          ...data.summaryData.productInformation.product,
          title: 'return_transfer',
        },
        icon: '/assets/icons/movemos-icon.svg',
      },
      locationInfo: {
        ...data.summaryData.locationInfo,
        originContactName:
          data.contactForm?.value.contactName || data.summaryData.locationInfo?.originContactName,
        originContactPhoneNumber:
          data.contactForm?.value.contactPhone ||
          data.summaryData.locationInfo?.originContactPhoneNumber,
        destinationContactName:
          data.contactForm?.value.contactName ||
          data.summaryData.locationInfo?.destinationContactName,
        destinationContactPhoneNumber:
          data.contactForm?.value.contactPhone ||
          data.summaryData.locationInfo?.destinationContactPhoneNumber,
        originAddress: data.summaryData.locationInfo?.destinationAddress,
        destinationAddress: data.summaryData.locationInfo?.originAddress,
        originAddressLatitude: data.summaryData.locationInfo?.destinationAddressLatitude,
        destinationAddressLatitude: data.summaryData.locationInfo?.originAddressLatitude,
        originAddressLongitude: data.summaryData.locationInfo?.destinationAddressLongitude,
        destinationAddressLongitude: data.summaryData.locationInfo?.originAddressLongitude,
      },
    };
    console.log(this.swappedSummaryData);
  }

  ngOnInit() {
    this.userService.getUserInfo().subscribe((user) => {
      this.userEmail = user.email;
    });
    this.getProductAvailability(
      this.swappedSummaryData.productInformation.product?.productId,
      this.selectedDate
    );
    const serviceStartDate = new Date(
      this.swappedSummaryData.locationInfo?.serviceStartDate
        ?.split(' ')[0]
        .replace('+02', '+02:00')
        .replace('+01', '+01:00') ?? new Date()
    );

    this.minDate = new Date(serviceStartDate.getTime());

    this.minDate.setHours(0, 0, 0, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSpecificHours']) {
      this.getProductAvailability(
        this.swappedSummaryData.productInformation.product?.productId,
        this.selectedDate
      );
    }
  }

  getProductAvailability(productId?: string, selectedDate?: Date, isReturn = false) {
    if (!productId) return;

    this.sharedFunnelService
      .getProductAvailability(
        productId,
        selectedDate || new Date(),
        isReturn ? this.isReturnSpecificHours : this.isSpecificHours,
        isReturn,
        this.data.summaryData
      )
      .subscribe({
        next: (hours: any) => {
          if (!isReturn) {
            if (!this.isSpecificHours) {
              this.availableStartHours = [...hours];
              this.availableEndHours = [...hours];

              if (this.availableStartHours.length > 0) {
                this.selectedStartHour = this.availableStartHours[0];
                this.handleStartTimeChange(this.selectedStartHour);
              }
            } else {
              this.availableHours = this.sharedFunnelService.transformHours(hours);
            }
          } else {
            this.availableReturnHours = this.sharedFunnelService.transformHours(hours);
          }
          this.noAvailableHours = hours.length === 0;
        },
        error: (error: any) => console.error('Error loading product availability', error),
      });
  }

  handleStartTimeChange(event: any) {
    const result = this.sharedFunnelService.handleStartTimeChange(
      event,
      this.availableStartHours,
      this.swappedSummaryData
    );
    this.availableEndHours = result.availableEndHours;
    this.noAvailableEndHours = result.noAvailableEndHours;
    this.selectedEndHour = result.selectedEndHour;
    this.warningMessage = result.warningMessage;
    const formattedStartDate = new Date(event.Key.split(' ')[0].split('+')[0]).toLocaleDateString(
      'es-ES',
      this.formattedDateOptions
    );
    const formattedStartTime = event.Text;
    const serviceStartDate = event.Key;
    const resultData = {
      formattedStartDate,
      formattedStartTime,
      serviceStartDate,
    };
    this.updateLocationInfo(resultData);
  }

  checkDateTime(selectedDate: Date) {
    if (this.sharedFunnelService.checkDateTime(selectedDate)) {
      this.summaryDataUpdated.emit(this.swappedSummaryData);
    } else {
      this.summaryDataUpdated.emit(this.swappedSummaryData);
    }
  }

  onClose(): void {
    const confirmationDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'sure_you_wanna_leave',
        message: 'disclousure_message_confirmation_dialog',
        confirmText: 'confirm',
        cancelText: 'cancel',
      },
    });

    confirmationDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.closeMainDialog();
      }
    });
  }

  private closeMainDialog(): void {
    this.dialogRef.close({ incompleted: true });
  }
  onBack(): void {
    if (this.currentIsFromMyServicesStep > 0) {
      this.currentIsFromMyServicesStep--;
    }
  }

  onSubmit(): void {
    this.swappedSummaryData.locationInfo!.comments = this.form.value.extraInfo;
    if (this.currentIsFromMyServicesStep === 0) {
      this.preBooking().subscribe(
        (updatedSummaryData) => {
          if (!this.data.isFromMyServices) {
            this.dialogRef.close({ incompleted: false, summaryData: updatedSummaryData });
          }
          this.currentIsFromMyServicesStep++;
        },
        (error) => {
          console.error('Error in preBooking:', error);
          this.loader.hide();
        }
      );
      return;
    }
    if (this.currentIsFromMyServicesStep === 1) {
      this.currentIsFromMyServicesStep++;
      return;
    }

    if (this.currentIsFromMyServicesStep === 2) {
      if (this.swappedSummaryData.orderHash) {
        this.finalOrder(this.swappedSummaryData.orderHash);
        this.dialogRef.close({ incompleted: false, summaryData: this.swappedSummaryData });
      }
    }
  }

  mapToppings(serverResponse: { Categories: ServerTopping[] }): MappedTopping[] {
    return serverResponse.Categories.map((category) => {
      const dictionaryInfo = myToppingDictionary[category.Title] || {};
      return {
        ...dictionaryInfo,
        title: category.Title,
        sortOrder: category.SortOrder,
        products: this.mapProductsWithRelated(category.Products, dictionaryInfo),
      };
    });
  }

  private mapProductsWithRelated(products: ServerProduct[], dictionaryInfo: any): MappedProduct[] {
    return products.map((product) => {
      const productDictionaryInfo = dictionaryInfo.relatedToppings?.[product.ProductKey] || {};

      const mappedProduct: MappedProduct = {
        Id: product.Id || productDictionaryInfo.id || '',
        id: product.Id || productDictionaryInfo.id || '',
        productKey: product.ProductKey,
        price: product.Price,
        priceTaxes: product.PriceTaxes,
        isBundle: product.IsBundle,
        verticalType: product.VerticalType,
        caflerFee: product.CaflerFee,
        caflerFeeTaxes: product.CaflerFeeTaxes,
        icon: productDictionaryInfo.icon || dictionaryInfo.icon,
        titleId: productDictionaryInfo.titleId || dictionaryInfo.titleId,
        descriptionId: productDictionaryInfo.descriptionId || dictionaryInfo.descriptionId,
        showStartingFrom: productDictionaryInfo.showStartingFrom ?? dictionaryInfo.showStartingFrom,
        relatedProducts: [],
      };

      if (product.IsBundle && product.RelatedProducts) {
        mappedProduct.relatedProducts = this.mapRelatedProducts(
          product.RelatedProducts,
          productDictionaryInfo.relatedToppings || {}
        );
      }

      return mappedProduct;
    });
  }

  private mapRelatedProducts(
    relatedProducts: ServerProduct[],
    relatedToppingsDictionary: any
  ): MappedProduct[] {
    return relatedProducts.map((relatedProduct) => {
      const relatedProductDictionaryInfo =
        relatedToppingsDictionary[relatedProduct.ProductKey] || {};

      return {
        Id: relatedProduct.Id,
        id: relatedProduct.Id,
        productKey: relatedProduct.ProductKey,
        price: relatedProduct.Price,
        priceTaxes: relatedProduct.PriceTaxes,
        isBundle: relatedProduct.IsBundle,
        verticalType: relatedProduct.VerticalType,
        caflerFee: relatedProduct.CaflerFee,
        caflerFeeTaxes: relatedProduct.CaflerFeeTaxes,
        icon: relatedProductDictionaryInfo.icon,
        titleId: relatedProductDictionaryInfo.titleId,
        descriptionId: relatedProductDictionaryInfo.descriptionId,
        showStartingFrom: relatedProductDictionaryInfo.showStartingFrom,
        relatedProducts: [],
      };
    });
  }

  charCount(): number {
    return this.maxChars - this.text.length;
  }

  onPickupDateChange(event: Date): void {
    this.checkDateTime(event);
    this.selectedDate = event;
    this.pickupDateSelected = !!event;
    if (this.isTransit()) {
      const startDate = new Date(event);
      const endDate = new Date(event);
      endDate.setHours(23, 59, 59, 999);
      if (this.swappedSummaryData.locationInfo) {
        (this.swappedSummaryData.locationInfo.formattedStartDate = event.toDateString()),
          (this.swappedSummaryData.locationInfo.formattedStartTime = ''),
          (this.swappedSummaryData.locationInfo.serviceStartDate =
            this.formatDateToCustomString(startDate)),
          (this.swappedSummaryData.locationInfo.serviceEndDate =
            this.formatDateToCustomString(endDate));
      }
    }
    this.getProductAvailability(
      this.swappedSummaryData.productInformation.product?.productId,
      this.selectedDate
    );
    this.noAvailableEndHours = false;
  }

  formatDateToCustomString(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Obtener el offset de la zona horaria en minutos
    const offsetMinutes = date.getTimezoneOffset();
    const offsetHours = Math.abs(Math.floor(offsetMinutes / 60))
      .toString()
      .padStart(2, '0');
    const offsetSign = offsetMinutes <= 0 ? '+' : '-';

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:00 Europe/Madrid`;
  }

  isTransit(): boolean {
    return this.swappedSummaryData.productInformation.product?.ProductKey === 'transit';
  }

  onTimeSlotChange(value: boolean) {
    this.isSpecificHours = value;
    this.resetTimeSlotSelection();
    this.getProductAvailability(
      this.swappedSummaryData.productInformation.product?.productId,
      this.selectedDate
    );
  }

  resetTimeSlotSelection() {
    const resetData = this.sharedFunnelService.resetTimeSlotSelection();
    Object.assign(this, resetData);
  }

  updateSelectedHours(event: any) {
    const serviceEndDate = event.Key;
    const formattedEndTime = event.Text;
    const resultData = {
      serviceEndDate,
      formattedEndTime,
    };
    this.updateLocationInfo(resultData);
  }
  updateLocationInfo(data: any) {
    this.swappedSummaryData.locationInfo = {
      ...this.swappedSummaryData.locationInfo,
      serviceStartDate:
        data.serviceStartDate ?? this.swappedSummaryData?.locationInfo?.serviceStartDate,
      serviceEndDate: data.serviceEndDate ?? this.swappedSummaryData?.locationInfo?.serviceEndDate,
      formattedStartDate:
        data.formattedStartDate ?? this.swappedSummaryData?.locationInfo?.formattedStartDate,
      formattedStartTime:
        data.formattedStartTime ?? this.swappedSummaryData?.locationInfo?.formattedStartTime,
      formattedEndTime:
        data.formattedEndTime ?? this.swappedSummaryData?.locationInfo?.formattedEndTime,
      serviceReturnStartDate:
        data.serviceReturnStartDate ??
        this.swappedSummaryData?.locationInfo?.serviceReturnStartDate,
      serviceReturnEndDate:
        data.serviceReturnEndDate ?? this.swappedSummaryData?.locationInfo?.serviceReturnEndDate,
      formattedReturnStartTime:
        data.formattedReturnStartTime ??
        this.swappedSummaryData?.locationInfo?.formattedReturnStartTime,
      formattedReturnEndTime:
        data.formattedReturnEndTime ??
        this.swappedSummaryData?.locationInfo?.formattedReturnEndTime,
      fee: data.fee ?? this.swappedSummaryData?.locationInfo?.fee,
    };
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  handleTimeSlotChange(event: any) {
    const locationInfo = this.sharedFunnelService.handleTimeSlotChange(event);
    const resultData = {
      serviceStartDate: locationInfo.serviceStartDate,
      serviceEndDate: locationInfo.serviceEndDate,
      formattedStartTime: locationInfo.formattedStartTime,
      formattedEndTime: locationInfo.formattedEndTime,
      fee: locationInfo.fee,
    };
    this.updateLocationInfo(resultData);
  }

  selectHour(hour: ConcatenatedHour) {
    this.selectedHourPair = hour;
    this.showDropdown = false;
    this.handleTimeSlotChange(hour);
  }

  formatDateTime(date: Date): string {
    return this.sharedFunnelService.formatDateTime(date);
  }

  transformHours(hours: Hour[]): ConcatenatedHour[] {
    return this.sharedFunnelService.transformHours(hours);
  }

  preBooking(): Observable<SummaryData> {
    this.loader.show();

    return this.funnelService.bookTransfer(this.swappedSummaryData, this.userEmail).pipe(
      tap((response) => {
        this.updateProductPrices(response);
        this.updateSummaryData(response);
        this.mappedToppings = this.mapToppings(response.AvailableToppings);

        this.loader.hide();
      }),
      map(() => {
        this.saveReturnTransferSummaryDataOnLocalStorage(this.swappedSummaryData);
        return this.swappedSummaryData;
      })
    );
  }

  finalOrder(orderHash: string) {
    this.loader.show();
    this.funnelService.finalBook(orderHash).subscribe((response) => {
      if (response) {
        this.alertService.setShowAlert(true);
        this.router.navigate(['/services']);
        this.loader.hide();
      }
    });
  }

  private updateSummaryData(response: any) {
    this.swappedSummaryData.orderHash = response.OrderHash;
    this.swappedSummaryData.availableToppings = response.AvailableToppings.Categories;
    this.swappedSummaryData.TotalOrderAmount = response.TotalOrderAmount;
    this.swappedSummaryData.TotalOrderTaxAmount = response.TotalOrderTaxAmount;
    this.swappedSummaryData.CouponInformation = response.CouponInformation;
    this.swappedSummaryData.FixedFee =
      response.Fees?.find((fee: any) => fee.Key === 'fixed-fee')?.Amount ?? 0;
  }
  private updateProductPrices(response: any) {
    const { Products, Fees, TotalOrderAmount, TotalOrderTaxAmount } = response;
    this.swappedSummaryData.TotalOrderAmount =
      TotalOrderAmount ?? this.swappedSummaryData.productInformation?.product?.Price;
    if (TotalOrderTaxAmount) this.swappedSummaryData.TotalOrderTaxAmount = TotalOrderTaxAmount;
    const product = this.swappedSummaryData.productInformation?.product;
    const caflerFee = Fees?.find((fee: any) => fee.Key === 'cafler-fee');
    const fixedFee = Fees?.find((fee: any) => fee.Key === 'fixed-fee');
    const urgencyFee = Fees?.find((fee: any) => fee.Key === 'urgency-fee');
    this.swappedSummaryData.FixedFee = fixedFee?.Amount ?? 0;
    this.swappedSummaryData.urgencyFee = urgencyFee?.Amount ?? 0;
    if (product) {
      const responseProduct = Products[0].Amount;
      product.Price = responseProduct;
      product.CaflerFee = caflerFee?.Amount ?? product.CaflerFee;
      const motKeys = [
        'mot-fuel-car-es',
        'mot-diesel-car-es',
        'mot-electric-car-es',
        'mot-motorbike-es',
      ];
      const addedNonScheduledMotPrice = Products.find((product: any) =>
        motKeys.includes(product.ProductKey)
      );
      product.addedNonScheduledMotPrice = addedNonScheduledMotPrice?.Amount;
    }
  }
  saveReturnTransferSummaryDataOnLocalStorage(returnTransferSummaryData: SummaryData) {
    localStorage.setItem('returnTransferSummaryData', JSON.stringify(returnTransferSummaryData));
  }
}
