export enum ServiceIcons {
    Transfer = 1,
    MechanicalInspection = 2,
    VehicleWash = 3,
    Refueling = 4,
    PreTechnicalInspection = 5,
    LongDistanceTransfer = 6,
    TechnicalInspection = 7,
    VehicleInsurance = 8,
    VehicleDataCollection = 9,
    Accessory = 10,
    Valet = 11,
    Tyres = 12,
    Formalities = 13,
    Parking = 14,
    ReplacementVehicle = 15,
    TowTruck = 16,
    WhateverYouWant = 17,
}
