import { Injectable } from '@angular/core';
import { fuelType } from '../constants/FuelType';
import { chassisType } from '../constants/ChassisType';
import { serviceType } from '../constants/ServiceType';
import { orderStatus } from '../constants/OrderStatus';
import { modalExtras } from '../constants/ModalExtras';
import { TranslateService } from '@ngx-translate/core';
import { gearboxType } from '../constants/GearboxType';
import { driveType } from '../constants/DriveType';
import { ecoStickerType } from '../constants/EcoSticker';
import { vehicleStatus } from '../constants/VehicleStatus';
import { itvStatus } from '../constants/ITVStatus';
import { currency } from '../constants/Currency';
import { paymentMethod } from '../constants/PaymentMethod';
import { insuranceType } from '../constants/InsuranceType';

@Injectable({
    providedIn: 'root',
})
export class GetValuesService {
    constructor(private translate: TranslateService) {}
    getFuelTypeString(id: number): string {
        const fuelTypeStr = fuelType[id];
        return fuelTypeStr ? this.translate.instant(fuelTypeStr) : this.translate.instant('undefined');
    }

    getFuelTypeNumber(type: string): number {
        for (const key in fuelType) {
            if (Object.prototype.hasOwnProperty.call(fuelType, key)) {
                if (fuelType[key] === type) {
                    return parseInt(key, 10);
                }
            }
        }
        return 1;
    }

    getAllFuelTypes(): { fuelTypeId: number; fuelTypeDescription: string }[] {
        const fuelTypes: { fuelTypeId: number; fuelTypeDescription: string }[] = [];

        Object.keys(fuelType).forEach((key) => {
            fuelTypes.push({
                fuelTypeId: parseFloat(key),
                fuelTypeDescription: this.translate.instant(fuelType[parseFloat(key)]),
            });
        });

        return fuelTypes;
    }

    getChassisTypeString(id: number): string {
        const chassisTypeObj = chassisType[id];
        return chassisTypeObj
            ? this.translate.instant(chassisTypeObj.description)
            : this.translate.instant('undefined');
    }

    getChassisTypeIcon(id: number): string {
        const chassisTypeObj = chassisType[id];
        return chassisTypeObj ? chassisTypeObj.icon : 'undefined';
    }

    getChassisTypeNumber(type: string): number {
        for (const key in chassisType) {
            if (Object.prototype.hasOwnProperty.call(chassisType, key)) {
                if (chassisType[key].description === type) {
                    return parseInt(key, 10);
                }
            }
        }
        return 1;
    }

    getAllChassisTypes(): string[] {
        const chassisTypes: string[] = [];
        Object.values(chassisType).forEach((value) => {
            chassisTypes.push(this.translate.instant(value.description));
        });
        return chassisTypes;
    }

    getServiceTypeString(id: number): string {
        return serviceType[id] ? this.translate.instant(serviceType[id]) : 'Desconocido';
    }

    getAllServiceTypes(): string[] {
        return Object.values(serviceType);
    }

    getOrderStatusString(id: number): string {
        return orderStatus[id] ? this.translate.instant(orderStatus[id]) : 'Desconocido';
    }

    getAllOrderStatus(): string[] {
        return Object.values(orderStatus);
    }

    getModalExtraValue(id: number): string {
        return modalExtras[id] || 'Desconocido';
    }

    getVehicleStatusString(id: number): string {
        return this.translate.instant(`vehicle_status_${id}`);
    }
    getDocumentTypeString(id: number): string {
        return this.translate.instant(`document_type_${id}`);
    }

    getReformTypeString(id: number): string {
        return this.translate.instant(`reform_type_${id}`);
    }

    getChassisTypeForSelect(): { value: number; text: string }[] {
        const chassisTypes: { value: number; text: string }[] = [];

        Object.keys(chassisType).forEach((key) => {
            chassisTypes.push({
                value: parseFloat(key),
                text: this.translate.instant(chassisType[parseFloat(key)].description),
            });
        });

        return chassisTypes;
    }

    getFuelTypeForSelect(): { value: number; text: string }[] {
        const fuelTypes: { value: number; text: string }[] = [];

        Object.keys(fuelType).forEach((key) => {
            fuelTypes.push({
                value: parseFloat(key),
                text: this.translate.instant(fuelType[parseFloat(key)]),
            });
        });

        return fuelTypes;
    }

    getGearboxTypeForSelect(): { value: number; text: string }[] {
        const result: { value: number; text: string }[] = [];

        Object.keys(gearboxType).forEach((key) => {
            result.push({
                value: parseFloat(key),
                text: this.translate.instant(gearboxType[parseFloat(key)]),
            });
        });

        return result;
    }

    getDriveTypeForSelect(): { value: number; text: string }[] {
        const result: { value: number; text: string }[] = [];

        Object.keys(driveType).forEach((key) => {
            result.push({
                value: parseFloat(key),
                text: this.translate.instant(driveType[parseFloat(key)]),
            });
        });

        return result;
    }

    getEcoStickerTypeForSelect(): { value: number; text: string }[] {
        const result: { value: number; text: string }[] = [];

        Object.keys(ecoStickerType).forEach((key) => {
            result.push({
                value: parseFloat(key),
                text: this.translate.instant(ecoStickerType[parseFloat(key)]),
            });
        });

        return result;
    }

    getVehicleStatusForSelect(): { value: number; text: string }[] {
        const result: { value: number; text: string }[] = [];

        Object.keys(vehicleStatus).forEach((key) => {
            result.push({
                value: parseFloat(key),
                text: this.translate.instant(vehicleStatus[parseFloat(key)]),
            });
        });

        return result;
    }

    getITVStatusForSelect(): { value: number; text: string }[] {
        const result: { value: number; text: string }[] = [];

        Object.keys(itvStatus).forEach((key) => {
            result.push({
                value: parseFloat(key),
                text: this.translate.instant(itvStatus[parseFloat(key)]),
            });
        });

        return result;
    }

    getCurrenciesForSelect(): { value: number; text: string }[] {
        const result: { value: number; text: string }[] = [];

        Object.keys(currency).forEach((key) => {
            result.push({
                value: parseFloat(key),
                text: this.translate.instant(currency[parseFloat(key)]),
            });
        });

        return result;
    }

    getPaymentMethodsForSelect(): { value: number; text: string }[] {
        const result: { value: number; text: string }[] = [];

        Object.keys(paymentMethod).forEach((key) => {
            result.push({
                value: parseFloat(key),
                text: this.translate.instant(paymentMethod[parseFloat(key)]),
            });
        });

        return result;
    }

    getInsuranceTypesForSelect(): { value: number; text: string }[] {
        const result: { value: number; text: string }[] = [];

        Object.keys(insuranceType).forEach((key) => {
            result.push({
                value: parseFloat(key),
                text: this.translate.instant(insuranceType[parseFloat(key)]),
            });
        });

        return result;
    }
}
