export enum Status {
    Initialized = 0,
    Pending = 1,
    Confirmed = 2,
    PickingUp = 3,
    InProgress = 4,
    Delivering = 5,
    Finished = 6,
    Failed = 7,
    CancelledByCafler = 10,
    CancelledByClient = 11,
}
