<div
    class="modal fade"
    id="tasaRepostajeModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="tasaRepostajeModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content contact-modal">
            <div class="close-box">
                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                    <img src="../../../../../assets/icons/close-modal.svg" alt="" />
                </button>
            </div>
            <div class="content">
                <div class="contact-box">
                    <h5 id="tasaRepostajeModalLabel">{{ 'refuelingFeeTitle' | translate }}</h5>
                    <p>
                        {{ 'refuelingFeeDescription' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
