import { Component, Input } from '@angular/core';
import { ServiceIcons } from './enum/ServiceIcons.enum';

@Component({
    selector: 'app-service-icons',
    templateUrl: './service-icons.component.html',
    styleUrls: ['./service-icons.component.scss'],
})
export class ServiceIconsComponent {
    @Input() serviceIconsValue = 0;
    @Input() isDatatable = false;
    @Input() isActiveRouteMap = false;

    serviceIconsEnum = ServiceIcons;

    getIconPath(icon: ServiceIcons): string {
        switch (icon) {
            case ServiceIcons.Transfer:
                return '../../../assets/service-icons/transfer.svg';
            case ServiceIcons.Parking:
                return '../../../assets/service-icons/parking.svg';
            case ServiceIcons.Refueling:
                return '../../../assets/service-icons/refueling.svg';
            case ServiceIcons.MechanicalInspection:
                return '../../../assets/service-icons/itv-homologacion.svg';
            case ServiceIcons.PreTechnicalInspection:
                return '../../../assets/service-icons/pre-itv.svg';
            case ServiceIcons.LongDistanceTransfer:
                return '../../../assets/service-icons/transfer.svg';
            case ServiceIcons.TechnicalInspection:
                return '../../../assets/service-icons/itv-españa.svg';
            case ServiceIcons.VehicleInsurance:
                return '../../../assets/service-icons/seguro.svg';
            case ServiceIcons.VehicleDataCollection:
                return '../../../assets/service-icons/lista-comprobacion.svg';
            case ServiceIcons.Accessory:
                return '../../../assets/service-icons/cafler-fresh.svg';
            case ServiceIcons.Valet:
                return '../../../assets/service-icons/valet.svg';
            case ServiceIcons.Tyres:
                return '../../../assets/service-icons/neumaticos.svg';
            case ServiceIcons.Formalities:
                return '../../../assets/service-icons/formalities.svg';
            case ServiceIcons.ReplacementVehicle:
                return '../../../assets/service-icons/vehiculo-de-sustitucion.svg';
            case ServiceIcons.TowTruck:
                return '../../../assets/service-icons/grua.svg';
            case ServiceIcons.WhateverYouWant:
                return '../../../assets/service-icons/pide-lo-que-necesites.svg';
            case ServiceIcons.VehicleWash:
                return '../../../assets/service-icons/lavados.svg';
            default:
                return '../../../assets/service-icons/transfer.svg';
        }
    }

    getAltText(icon: ServiceIcons): string {
        switch (icon) {
            case ServiceIcons.Transfer:
                return 'Transfer';
            case ServiceIcons.Parking:
                return 'Parking';
            case ServiceIcons.Refueling:
                return 'Refueling';
            case ServiceIcons.MechanicalInspection:
                return 'Mechanical inspection';
            case ServiceIcons.PreTechnicalInspection:
                return 'Pre technical inspection';
            // TODO: revisar icono, no correcto
            case ServiceIcons.LongDistanceTransfer:
                return 'Long disntance transfer';
            case ServiceIcons.TechnicalInspection:
                return 'Technical inspection';
            case ServiceIcons.VehicleInsurance:
                return 'vehicle insurance';
            case ServiceIcons.VehicleDataCollection:
                return 'Vehicle data collection';
            // TODO: revisar icono, no correcto
            case ServiceIcons.Accessory:
                return 'Accessory';
            case ServiceIcons.Valet:
                return 'Valet';
            case ServiceIcons.Tyres:
                return 'Tyres';
            // TODO: revisar icono, no correcto
            case ServiceIcons.Formalities:
                return 'Formalities';
            case ServiceIcons.ReplacementVehicle:
                return 'Replacement vehicle';
            case ServiceIcons.TowTruck:
                return 'Tow truck';
            case ServiceIcons.WhateverYouWant:
                return 'What ever you want';
            case ServiceIcons.VehicleWash:
                return 'Vehicle wash';
            default:
                return 'No image';
        }
    }
}
