<section [ngClass]="{ 'scrollable-table': isHomePage, 'home-datatable-border': isHomePage }">
    <table class="table" *ngIf="showDataWithLimit.length; else emptyHomePage">
        <thead>
            <tr>
                <th scope="col">{{ 'orderHash' | translate }}</th>
                <th scope="col">{{ 'plateNumber' | translate }}</th>
                <th scope="col">{{ 'pickupTime' | translate }}</th>
                <th scope="col">{{ 'service_date' | translate }}</th>
                <th scope="col">{{ 'service' | translate }}</th>
                <th scope="col">{{ 'pickUp' | translate }}</th>
                <th scope="col">{{ 'return' | translate }}</th>
                <th scope="col">{{ 'driver' | translate }}</th>
                <th scope="col">{{ 'status' | translate }}</th>
                <th scope="col" *ngIf="!isHomePage">&nbsp;</th>
            </tr>
        </thead>
        <tbody *ngIf="!loading">
            <tr
                *ngFor="let data of showDataWithLimit"
                (contextmenu)="openContextMenu($event, data.orderHash)"
                (click)="openDetails($event, data.orderHash)"
            >
                <td class="py-3 td-title">{{ data.orderHash }}</td>
                <td class="py-3"><app-plate-number [plateValue]="data.plateNumber"></app-plate-number></td>
                <td class="py-3">{{ data.hour }}</td>
                <td class="py-3">{{ data.date }}</td>
                <td class="py-3 td-services">
                    <ng-container *ngFor="let service of data.services">
                        <app-service-icons [isDatatable]="true" [serviceIconsValue]="service"></app-service-icons>
                    </ng-container>
                </td>
                <td class="py-3 text-overflow-ellipsis">{{ data.pickUpAddress }}</td>
                <td class="py-3 text-overflow-ellipsis">
                    {{ data.returnAddress ? data.returnAddress : data.pickUpAddress }}
                </td>
                <td class="py-3">{{ data.driver ? data.driver : ' - ' }}</td>
                <td class="py-3"><app-status [statusValue]="data.status"></app-status></td>
                <td class="" *ngIf="!isHomePage">
                    <div class="dots-menu">
                        <button (click)="openContextMenu($event, data.orderHash, true)" tabindex="0">
                            <img src="../../../assets/icons/datatable-dots-horizontal.svg" alt="Dots Menu" />
                        </button>
                    </div>

                    <div
                        class="context-menu"
                        *ngIf="contextMenuVisible && selectedOrderHash === data.orderHash"
                        [style.position]="'fixed'"
                        [style.top.px]="contextMenuPosition.y"
                        [style.left.px]="contextMenuPosition.x"
                        (click)="$event.stopPropagation()"
                        (keydown)="$event.stopPropagation()"
                        tabindex="0"
                    >
                        <ul class="dots-dropdown">
                            <li>
                                <a [routerLink]="['/active-routes', data.orderHash, 'detail']">
                                    <img src="../../../assets/icons/datatable-eye.svg" alt="Ver Detalle" />
                                    <span>{{ 'viewDetail' | translate }}</span>
                                </a>
                            </li>
                            <li *ngIf="isTransfer(data.services)">
                                <button (click)="openFunnelDialog(data.orderHash)">
                                    <img src="../../assets/icons/navbar/nav-services.svg" alt="return transfer icon" />
                                    <span>{{ 'ask_for_return_transfer' | translate }}</span>
                                </button>
                            </li>
                            <li
                                [ngClass]="{
                                    disabled: !(
                                        statusEnum.Finished === data.status ||
                                        statusEnum.Failed === data.status ||
                                        statusEnum.CancelledByCafler === data.status ||
                                        statusEnum.CancelledByClient === data.status
                                    ),
                                }"
                            >
                                <button (click)="downloadService(data.orderHash, data.status)">
                                    <img src="../../../assets/icons/datatable-cloud-download.svg" alt="Ver Detalle" />
                                    <span>{{ 'download' | translate }}</span>
                                </button>
                            </li>
                            <li
                                [ngClass]="{
                                    disabled: !(
                                        statusEnum.Initialized === data.status ||
                                        statusEnum.Pending === data.status ||
                                        statusEnum.Confirmed === data.status
                                    ),
                                }"
                            >
                                <button (click)="openModal(data.orderHash, data.status)">
                                    <img src="../../../assets/icons/datatable-trash-red.svg" alt="Ver Detalle" />
                                    <span class="red-span">{{ 'modalCancel' | translate }}</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <div
        *ngIf="showDataWithLimit.length && !isHomePage && !loading"
        class="d-flex flex-row justify-content-between align-items-center"
    >
        <div class="pagination-info">
            {{ 'pagination_info_1' | translate }} <span>{{ page }}</span> {{ 'pagination_info_2' | translate }}
            <span>{{ pages.length }}</span>
        </div>

        <div class="pagination">
            <div class="button" (keydown)="prevPage()" (click)="prevPage()" tabindex="0">
                <img src="../../../assets/icons/chevron-left.svg" class="img-rotate" alt="Arrow left" />
            </div>
            <ng-container *ngFor="let generatePage of pages">
                <div *ngIf="generatePage === -1" class="button">...</div>
                <div
                    *ngIf="generatePage !== -1"
                    class="button"
                    [ngClass]="{ 'button-selected': generatePage === page }"
                    (click)="setPage(generatePage)"
                    (keydown)="setPage(generatePage)"
                    tabindex="0"
                >
                    {{ generatePage }}
                </div>
            </ng-container>
            <div *ngIf="pages.length === 1" class="button" (keydown)="nextPage()" (click)="nextPage()" tabindex="0">
                <img src="../../../assets/icons/chevron-left.svg" alt="Arrow right" />
            </div>
            <div *ngIf="pages.length > 1" class="button" (keydown)="nextPage()" (click)="nextPage()" tabindex="0">
                <img src="../../../assets/icons/chevron-right.svg" alt="Arrow right" />
            </div>
        </div>
    </div>

    <ng-template #emptyHomePage>
        <div *ngIf="isHomePage && !loading" class="d-flex flex-column align-items-center w-100 no-services-content">
            <div class="empty-home-page d-flex flex-column align-items-center">
                <img src="../../../assets/empty-home-services.svg" alt="Empty home page" />
                <h4>{{ 'nothingInView' | translate }}</h4>
                <p class="text-center">{{ 'noServicesToday' | translate }}</p>
            </div>
        </div>
        <div
            *ngIf="(isMyServicesToday || isMyServicesNext || isMyServicesHistory) && !loading"
            class="d-flex flex-column align-items-center w-100"
        >
            <div class="d-flex flex-column align-items-center w-25">
                <img src="../../../assets/icons/no-active-routes.svg" alt="No active routes available" />
                <div>
                    <h2 class="text-center">{{ 'noRoutesAvailable' | translate }}</h2>
                    <p class="text-center">{{ 'connectionProblems' | translate }}</p>
                </div>

                <a [routerLink]="['/new-service']" class="w-100 no-service-button">{{
                    'requestService' | translate
                }}</a>
            </div>
        </div>
    </ng-template>

    <app-spinner-datatable *ngIf="loading"></app-spinner-datatable>
</section>
