import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/auth/auth.service';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HomeService {
    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {}

    url = environment.tracking_url;

    serviceCount() {
        return from(this.authService.getAccessToken()).pipe(
            switchMap((token) => {
                if (!token) {
                    throw new Error('No token available');
                }
                return this.http.get(`${this.url}/fleet-manager/services/count`, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                });
            })
        );
    }

    servicesGantt() {
        return from(this.authService.getAccessToken()).pipe(
            switchMap((token) => {
                if (!token) {
                    throw new Error('No token available');
                }
                return this.http.get(`${this.url}/fleet-manager/services/gantt`, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                });
            })
        );
    }

    servicesMap() {
        return from(this.authService.getAccessToken()).pipe(
            switchMap((token) => {
                if (!token) {
                    throw new Error('No token available');
                }
                return this.http.get(`${this.url}/fleet-manager/services/map`, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                });
            })
        );
    }

    servicesList() {
        return from(this.authService.getAccessToken()).pipe(
            switchMap((token) => {
                if (!token) {
                    throw new Error('No token available');
                }
                return this.http.get(`${this.url}/fleet-manager/services/list`, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                });
            })
        );
    }
}
