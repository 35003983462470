import { Component,HostListener,AfterViewInit,AfterViewChecked, Input  } from '@angular/core';
import { GetValuesService } from 'src/app/core/services/get-values.service';
import { IGanttData } from './interfaces/GanttData.interface';
import { IGanttTask } from './interfaces/GanttTask.interface';




@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss']
})
export class GanttComponent  implements AfterViewChecked {
  constructor(
    private getValuesService: GetValuesService
) {}

  ganttStartTime:number = 6;
  ganttEndTime:number = 21;
  rangeWidth = 100;
  hours: string[] = this.getHoursOfDay(this.ganttStartTime, this.ganttEndTime);

  @Input() ganttData: IGanttData = {rows:[]};
  
  // : IGanttData = {
  //   rows: [
  //     {
  //       tasks:[
  //         { caflerHash: 'B-45LKM74',licensePlate:'8888PKR', startHour: 10, endHour: 12, status:6,productTypes:[1] },
  //         { caflerHash: 'B-00KLJF1', licensePlate:'8888PKR',  startHour: 12.5, endHour: 14 , status:3,productTypes:[2,3] },
  //         { caflerHash: 'B-00KFRT1', licensePlate:'8888PKR', startHour: 17, endHour: 18 , status:1,productTypes:[4,5] },
  //       ]
  //     },
  //     {
  //       tasks:[
  //         { caflerHash: 'B-45LKM74', licensePlate:'8888PKR', startHour: 8, endHour: 12, status:6,productTypes:[1,7,4]  },
  //         { caflerHash: 'B-00KLJF1', licensePlate:'8888PKR', startHour: 14, endHour: 18, status:2 ,productTypes:[11] },
  //       ]
  //     },
  //     {
  //       tasks:[
  //         { caflerHash: 'B-45LKM74', licensePlate:'8888PKR', startHour: 8.5, endHour: 10.25, status:11,productTypes:[14] },
  //         { caflerHash: 'B-00KLJF1', licensePlate:'8888PKR', startHour: 10.25, endHour: 12.75, status:5,productTypes:[12,4]  },
  //         { caflerHash: 'B-00KFRT1', licensePlate:'8888PKR', startHour: 13, endHour: 18 , status:4,productTypes:[3,4] },
  //       ]
  //     },

  //     {
  //       tasks:[
  //         { caflerHash: 'B-45LKM74',licensePlate:'8888PKR', startHour: 10, endHour: 12, status:6,productTypes:[1] },
  //         { caflerHash: 'B-00KLJF1', licensePlate:'8888PKR',  startHour: 12.5, endHour: 14 , status:3,productTypes:[2,3] },
  //         { caflerHash: 'B-00KFRT1', licensePlate:'8888PKR', startHour: 17, endHour: 18 , status:1,productTypes:[2] },
  //       ]
  //     },
  //     {
  //       tasks:[
  //         { caflerHash: 'B-45LKM74', licensePlate:'8888PKR', startHour: 8, endHour: 12, status:6,productTypes:[1,7,4]  },
  //         { caflerHash: 'B-00KLJF1', licensePlate:'8888PKR', startHour: 14, endHour: 18, status:2 ,productTypes:[11] },
  //       ]
  //     },
  //     {
  //       tasks:[
  //         { caflerHash: 'B-45LKM74', licensePlate:'8888PKR', startHour: 8.5, endHour: 10.25, status:11,productTypes:[14] },
  //         { caflerHash: 'B-00KLJF1', licensePlate:'8888PKR', startHour: 10.25, endHour: 12.75, status:5,productTypes:[12,4]  },
  //         { caflerHash: 'B-00KFRT1', licensePlate:'8888PKR', startHour: 17.5, endHour: 18 , status:4,productTypes:[2,4] },
  //       ]
  //     },
      
  //   ]
  // }

  ngAfterViewChecked() {
    const div = document.querySelector('.gantt-main-container') as HTMLDivElement;
    if (div) {
      const containerWidth = div.offsetWidth ?? 500;
      div.scrollLeft = this.getCurrentTimeScrollingWidth()-(containerWidth/2);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    
  }  


  /**Data functions */
  getServiceType(id?: number): string {
    if (!id) return 'Desconocido';
    return this.getValuesService.getServiceTypeString(id);
  }

  /**Logic functions */
  getCurrentTimeInDecimal(){
    const now = new Date();
    return now.getHours() + (now.getMinutes() / 60);
  }

  getHoursOfDay(startHour:number, endHour:number){
    let times:string[] = [];
    for (let i = startHour; i <= endHour; i++) {
      const hour = i < 10 ? '0' + i : i.toString();
      times.push(`${hour}:00`);
      times.push(`${hour}:30`);
      }
      return times;
  }

  getCurrentTimeScrollingWidth(): number{
    const mainContainer = document.querySelector('.gantt-main-container') as HTMLDivElement;
    
    let currentTime = this.getCurrentTimeInDecimal();
    if(currentTime > this.ganttEndTime){
      currentTime = this.ganttEndTime;
    }

    let startTimeDiff = currentTime - this.ganttStartTime;    

    return startTimeDiff * (this.rangeWidth*2) + (this.rangeWidth/2) ;
  }


  /**Styles functions */
  getStatusStyle(task: IGanttTask){

    switch(task.status){
      case 1: return 'gantt-task-pending';
      case 2: return 'gantt-task-confirmed';
      case 3: case 4 : case 5: return 'gantt-task-inProgress' ;
      case 6: return 'gantt-task-finished';
      case 10: case 11: return 'gantt-task-cancelled';
      default: return 'gantt-task-confirmed';
    }
  }

  getPositionStyle(task: IGanttTask): { [key: string]: string } {

    const mainContainer = document.querySelector('.gantt-main-container') as HTMLDivElement;

    let startTimeDiff = task.startHour - this.ganttStartTime;    
    let endTimeDiff = task.endHour - task.startHour;

    let left = startTimeDiff * (this.rangeWidth*2) + (this.rangeWidth/2)  + 'px';
    let width = endTimeDiff * (this.rangeWidth*2)   +'px';

    return {
      left,
      width
    };
  }

  
  getCurrentTimeStyle(): { [key: string]: string } {

    const left = this.getCurrentTimeScrollingWidth() + 'px';
    return {
      left
    };
  }

  

}
