import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderDatatableService } from 'src/app/core/services/loader-datatable.service';

@Component({
    selector: 'app-spinner-datatable',
    templateUrl: './spinner-datatable.component.html',
    styleUrls: ['./spinner-datatable.component.scss'],
})
export class SpinnerDatatableComponent implements OnInit, OnDestroy {
    loading = false;
    private subscription: Subscription = new Subscription();

    constructor(private loader: LoaderDatatableService) {}

    ngOnInit(): void {
        this.subscription = this.loader.getLoading().subscribe((loading: boolean) => {
            this.loading = loading;
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
