import { Component, Input } from '@angular/core';
import { Status } from './enum/Status.enum';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
    @Input() statusValue = 0;
    statusEnum = Status;
}
