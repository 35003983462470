import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LogoutComponent } from './shared/logout/logout.component';
import { TranslationResolver } from './services/translation-resolver.service';
import { AlertGuard } from './core/guards/alert.guard';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
        data: { titleKey: 'homeTitle', icon: '../assets/icons/cafler.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
        data: { titleKey: 'loginTitle', icon: '../assets/icons/cafler.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule),
        data: { titleKey: 'settingsTitle', icon: '../assets/icons/cafler.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'active-routes',
        loadChildren: () => import('./pages/active-routes/active-routes.module').then((m) => m.ActiveRoutesModule),
        data: { titleKey: 'activeRoutesTitle', icon: '../assets/icons/cafler.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'billing',
        loadChildren: () => import('./pages/billing/billing.module').then((m) => m.BillingModule),
        data: { titleKey: 'billingTitle', icon: '../assets/icons/cafler.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'password-recovery',
        loadChildren: () =>
            import('./pages/password-recovery/password-recovery.module').then((m) => m.PasswordRecoveryModule),
        data: { titleKey: 'passwordRecoveryTitle', icon: '../assets/icons/cafler.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'chat',
        loadChildren: () => import('./pages/chat/chat.module').then((m) => m.ChatModule),
        data: { titleKey: 'chatTitle', icon: '../assets/icons/cafler.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'my-account',
        loadChildren: () => import('./pages/my-account/my-account.module').then((m) => m.MyAccountModule),
        data: { titleKey: 'myAccountTitle', icon: '../assets/icons/cafler.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'services',
        loadChildren: () => import('./pages/my-services/my-services.module').then((m) => m.MyServicesModule),
        data: { titleKey: 'servicesTitle', icon: '../assets/icons/navbar/nav-services.svg' },
        canActivate: [MsalGuard, AlertGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'new-service',
        loadChildren: () => import('./pages/new-service/new-service.module').then((m) => m.NewServiceModule),
        data: { titleKey: 'newServiceTitle', icon: '../assets/icons/navbar/nav-new-service.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'addresses',
        loadChildren: () => import('./pages/addresses/addresses.module').then((m) => m.AddressesModule),
        data: { titleKey: 'addressesTitle', icon: '../assets/icons/navbar/nav-address.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersModule),
        data: { titleKey: 'users_title', icon: '../assets/icons/navbar/nav-users.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'fleets',
        loadChildren: () => import('./pages/fleets/fleets.module').then((m) => m.FleetsModule),
        data: { titleKey: 'fleetsTitle', icon: '../assets/icons/navbar/nav-fleets.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule),
        data: { titleKey: 'reportsTitle', icon: '../assets/icons/navbar/nav-chart-bar.svg' },
        canActivate: [MsalGuard],
        resolve: { title: TranslationResolver },
    },
    {
        path: 'logout',
        canActivate: [MsalGuard],
        component: LogoutComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
