import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
    @Input() steps: string[] = [];
    @Input() activeStep = 0;
    @Input() isVertical = false;
    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        console.log(this.activeStep);
    }

    redirectToStep(step: number): void {
        if (this.isVertical) return;
        if (this.activeStep < step) {
            return;
        }
        if (this.activeStep === step) {
            return;
        }
        this.router.navigate(['.', { step: step + 1 }], {
            relativeTo: this.route,
            replaceUrl: true,
        });
    }
}
