<div class="d-flex dialog-container w-100">
    <div class="extras-container d-flex flex-column">
        <div class="title-and-description d-flex">
            <img src="../../../../../assets/icons/new-service-calendar.svg" alt="calendar-icon" />
            <h3>{{ 'extrasHeader' | translate }}</h3>
        </div>
        <div class="d-flex stepper-container">
            <span>{{ 'data' | translate }}</span> <span class="chevron">></span> <span>{{ 'extras' | translate }}</span>
        </div>
        <div class="extra-options d-flex flex-column">
            <div
                *ngFor="let topping of mappedToppings"
                class="extra-option"
                [ngClass]="{ selected: form.get(topping.id)?.value }"
            >
                <div class="d-flex flex-row gap-column w-100">
                    <div class="d-flex flex-column icon-container">
                        <img [src]="topping.icon" alt="Info icon" class="icon-size" />
                    </div>
                    <div class="d-flex flex-column gap-column w-100">
                        <div class="gap-title-option d-flex flex-column">
                            <div class="d-flex flex-row justify-content-between align-items-center">
                                <div class="d-flex gap-within-title-icon">
                                    <span class="title-option">{{ topping.id | translate }}</span>
                                    <div>
                                        <img
                                            src="/assets/icons/information-circle.svg"
                                            alt="Info icon"
                                            class="icon-size-small"
                                            [ngClass]="{ 'icon-disabled': form.get('noExtras')?.value }"
                                            (click)="openInfoModal(topping)"
                                            (keyup)="openInfoModal(topping)"
                                            tabindex="0"
                                        />
                                    </div>
                                </div>
                                <form [formGroup]="form">
                                    <label class="switch">
                                        <input
                                            type="checkbox"
                                            [formControlName]="topping.id"
                                            (change)="onToggleChange(topping.id, $event)"
                                        />
                                        <span class="slider round">
                                            <img src="assets/icons/check-circle.svg" alt="" class="check-icon" />
                                        </span>
                                    </label>
                                </form>
                            </div>
                            <div class="d-flex flex-row option-content">
                                {{ topping.descriptionId | translate }}
                            </div>
                        </div>
                        <div class="d-flex flex-row align-self-end title-option" *ngIf="topping.showStartingFrom">
                            <span class="from-tag">{{ 'startingFrom' | translate }}</span
                            ><span class="price-tag">{{ topping.products[0].price }}</span>
                        </div>
                        <div class="d-flex flex-row align-self-end title-option" *ngIf="!topping.showStartingFrom">
                            {{ topping.products[0].price }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- No Extras Toggle -->
            <div
                class="extra-option no-extras justify-content-between"
                [ngClass]="{ selected: form.get('noExtras')?.value }"
            >
                <span class="title-option">{{ 'noExtras' | translate }}</span>
                <form [formGroup]="form">
                    <label class="switch">
                        <input
                            type="checkbox"
                            [formControlName]="'noExtras'"
                            (change)="onToggleChange('noExtras', $event)"
                            (click)="removeAllExtras()"
                        />
                        <span class="slider round"></span>
                    </label>
                </form>
            </div>
        </div>
    </div>
</div>
