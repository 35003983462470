import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private loadingCount = 0;
    private loadingSubject = new BehaviorSubject<boolean>(false);

    getLoading() {
        return this.loadingSubject.asObservable();
    }

    show() {
        this.loadingCount++;
        this.loadingSubject.next(true);
    }

    hide() {
        if (this.loadingCount > 0) {
            this.loadingCount--;
        }
        if (this.loadingCount === 0) {
            this.loadingSubject.next(false);
        }
    }
}
