<ng-container *ngIf="summaryData.serviceConfiguration?.appointmentTime">
    <hr class="divider" />
    <div class="d-flex flex-column">
        <span class="aditional-info-header">{{ 'aditionalInfo' | translate }}</span>
        <div class="d-flex flex-column" *ngIf="summaryData.serviceConfiguration?.appointmentHour">
            <span class="title">{{ 'appointment_hour' | translate }}</span>
            <span>{{ summaryData.serviceConfiguration?.appointmentHour }}</span>
        </div>
        <div class="d-flex flex-column" *ngIf="summaryData.serviceConfiguration?.appointmentCode">
            <span class="title">{{ 'appointment_code' | translate }}</span>
            <span>{{ summaryData.serviceConfiguration?.appointmentCode }}</span>
        </div>
        <div class="d-flex flex-column" *ngIf="summaryData.serviceConfiguration?.stationName">
            <span class="title">{{ 'station' | translate }}</span>
            <span>{{ summaryData.serviceConfiguration?.stationName }}</span>
        </div>
    </div>
</ng-container>
