import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';
import { PersonalizationId } from 'src/app/pages/new-service/components/new-service-personalization/new-service-personalization.component';
import { FunnelService } from 'src/app/pages/new-service/services/funnel.service';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-checkout-container',
    templateUrl: './checkout-container.component.html',
    styleUrls: ['./checkout-container.component.scss'],
})
export class CheckoutContainerComponent {
    @Input() summaryData: SummaryData = {} as SummaryData;
    @Input() returnTransferSummaryData: SummaryData = {} as SummaryData;
    @Input() currentStep = 0;
    @Input() disabledButtonLastStep = false;
    @Input() buttonText = '';
    @Input() minimumConditions = false;
    @Input() termsAndConditions = false;
    @Input() productSelectionStepForm: FormGroup = new FormGroup({});
    @Output() buttonClick = new EventEmitter<void>();
    @Output() deletePromoCode = new EventEmitter<void>();
    @Input() totalValue = 0;
    @Output() promoCodeApplied = new EventEmitter<string>();
    @Output() promoCodeRemoved = new EventEmitter<void>();
    personalizationId = PersonalizationId;
    showPromoCodeInput = false;
    promoCode = '';
    appliedPromoCode = '';
    promoCodeError = '';

    constructor(private funnelService: FunnelService) {}

    shouldShowCheckoutContainer(): boolean {
        if (this.summaryData?.productInformation?.product) {
            return this.summaryData?.productInformation?.product && this.currentStep !== 3 && this.currentStep !== 2;
        } else return false;
    }

    shouldShowCoupon(): boolean {
        if (this.summaryData.Coupons && this.summaryData.Coupons.length > 0) {
            return this.currentStep === 6 && this.summaryData.Coupons[0]?.CouponApplied;
        } else return false;
    }

    shouldShowBillingSummary(): boolean {
        return (
            (this.currentStep > 3 &&
                this.summaryData?.productInformation?.product?.ProductKey !== this.personalizationId.MotHomologation) ||
            this.currentStep === 6
        );
    }

    getDisplayedTotalValue(): number {
        if (
            this.summaryData.TotalOrderAmount &&
            this.returnTransferSummaryData &&
            this.returnTransferSummaryData.TotalOrderAmount
        ) {
            return this.returnTransferSummaryData.TotalOrderAmount + this.summaryData.TotalOrderAmount;
        }
        return this.summaryData.TotalOrderAmount || this.totalValue;
    }

    onButtonClick(): void {
        this.buttonClick.emit();
    }

    onDeletePromoCode(): void {
        this.deletePromoCode.emit();
    }

    applyPromoCode(): void {
        if (this.promoCode && this.summaryData.orderHash) {
            this.funnelService.validatePromotionCode(this.promoCode, this.summaryData.orderHash).subscribe(
                (response: any) => {
                    if (response.CouponApplied) {
                        this.appliedPromoCode = this.promoCode;
                        this.promoCodeError = '';
                        this.promoCodeApplied.emit(this.promoCode);
                    } else {
                        this.promoCodeError = 'Invalid promotion code';
                    }
                },
                (error) => {
                    this.promoCodeError = 'Error applying promotion code';
                }
            );
        }
    }

    removePromoCode(): void {
        if (this.summaryData.orderHash) {
            this.funnelService.deletePromotionCode(this.summaryData.orderHash).subscribe(
                () => {
                    this.appliedPromoCode = '';
                    this.promoCode = '';
                    this.promoCodeRemoved.emit();
                },
                (error) => {
                    console.error('Error removing promotion code:', error);
                }
            );
        }
    }

    updateButtonState(): void {
        this.disabledButtonLastStep = !(this.minimumConditions && this.termsAndConditions);
    }

    isOutOfRange(): boolean {
        return this.summaryData.productInformation.product?.outOfRange ?? false;
    }
    isTransit(): boolean {
        return this.summaryData.productInformation.product?.ProductKey === this.personalizationId.Transit;
    }
}
