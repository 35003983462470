import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InteractionRequiredAuthError, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly scopes = [
        'https://identity.cafler.com/my-identity/Business.Identity',
        'https://identity.cafler.com/my-identity/Business.Services',
    ];

    constructor(private msalService: MsalService) {}

    login() {
        const loginRequest: RedirectRequest = {
            scopes: this.scopes,
        };
        this.msalService.loginRedirect(loginRequest);
    }

    logout(): void {
        this.msalService.logoutRedirect({
            postLogoutRedirectUri: '/login',
        });
    }

    isLoggedIn(): boolean {
        return this.msalService.instance.getAllAccounts().length > 0;
    }

    private async acquireTokenSilent(scopes: string[]): Promise<string | undefined> {
        const account = this.msalService.instance.getAllAccounts()[0];
        if (!account) {
            console.error('No user account found');
            this.logout();
            return undefined;
        }

        const silentRequest: SilentRequest = {
            scopes: scopes,
            account: account,
        };

        try {
            const response = await from(this.msalService.acquireTokenSilent(silentRequest))
                .pipe(
                    map((response) => {
                        if (!response.accessToken) {
                            throw new Error('Token is undefined');
                        }
                        return response.accessToken;
                    }),
                    catchError((error) => {
                        console.error('Failed to acquire token silently', error);
                        if (error instanceof InteractionRequiredAuthError) {
                            this.msalService.acquireTokenRedirect(silentRequest as RedirectRequest);
                        }
                        return of(undefined);
                    })
                )
                .toPromise();

            if (!response) {
                console.error('There is no response');
                return undefined;
            }

            return response;
        } catch (error: any) {
            console.error('Error getting access token', error);
            if (error.errorCode === 'interaction_in_progress') {
                console.error('Interaction is currently in progress, please try again later.');
                return undefined;
            }
            this.logout();
            return undefined;
        }
    }

    public async getAccessToken(): Promise<string | undefined> {
        const token = await this.acquireTokenSilent(this.scopes);
        if (!token) {
            console.error('Failed to retrieve bearer token');
            this.logout();
            return undefined;
        }
        return token;
    }

    changePassword(): void {
        const resetPasswordRequest: RedirectRequest = {
            authority: environment.azureActiveDirectory.authority,
            scopes: ['https://identity.cafler.com/my-identity/Business.Identity'],
        };

        this.msalService.loginRedirect(resetPasswordRequest);
    }
}
