import { Component, Input } from '@angular/core';
import { SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';

@Component({
    selector: 'app-additional-info',
    templateUrl: './additional-info.component.html',
    styleUrls: ['./additional-info.component.scss'],
})
export class AdditionalInfoComponent {
    @Input() summaryData: SummaryData = {} as SummaryData;
}
