<div class="modal-container">
    <div class="modal-exit">
        <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
    </div>
    <div class="modal-info">
        <div class="modal-title">{{ 'cancelModalTitle' | translate }}</div>
        <div class="modal-text">
            {{ 'cancelModalText' | translate }}<br />
            {{ 'cancelModalText1' | translate }}
            <a
                href="https://cafler.atlassian.net/wiki/external/M2E4YWE5ZDczYzViNGJmMzllNzg2MWRiNzczOGJkYTQ"
                target="_blank"
                >{{ 'cancelModalPolicies' | translate }}.</a
            >
        </div>
    </div>
    <div class="modal-buttons">
        <button type="button" class="cancel-button" (click)="onClose()">
            {{ 'cancelModalKeep' | translate }}
        </button>
        <button type="button" class="delete-button" (click)="cancelService()">
            {{ 'cancelModalCancel' | translate }}
        </button>
    </div>
</div>
