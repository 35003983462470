import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerComponent } from './core/components/spinner/spinner.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent, SpinnerComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatDialogModule,
        MatSlideToggleModule,
        SharedModule,
        AuthModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    exports: [SpinnerComponent],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
    constructor(private translate: TranslateService) {
        const savedLanguage = localStorage.getItem('language') || 'es';
        translate.setDefaultLang(savedLanguage);
        translate.use(savedLanguage);
    }
}
