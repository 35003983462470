<div class="gantt-main-container" >   

    <div class="gantt-container" *ngIf="ganttData.rows.length; else emptyGantt">

        <div class="gantt-time-grid">
            <div class="gantt-time-grid-cell" *ngFor="let hour of hours"></div>
        </div>
    
        <div class="current-time-container">
            <div class="current-time"  [ngStyle]="getCurrentTimeStyle()"></div> 
        </div>   

        <div class="gantt-header">
          <div class="gantt-header-cell" *ngFor="let hour of hours">{{ hour }}</div>
        </div>

        <div class="gantt-body">
          <div class="gantt-row" *ngFor="let row of ganttData.rows">
            <div class="gantt-task" [routerLink]="['/active-routes', task.caflerHash, 'detail']" *ngFor="let task of row.tasks" [ngStyle]="getPositionStyle(task)" [ngClass]="getStatusStyle(task)" title="{{task.licensePlate}} - {{task.caflerHash}}">
                <div class="gantt-task-data">
                   <div class="gantt-task-products"> 
                    <div class="gantt-task-product" *ngFor="let productType of task.productTypes">
                        <app-service-icons [isDatatable]="true" [serviceIconsValue]="productType"></app-service-icons>
                        <span>{{getServiceType(productType)}}</span>                        
                    </div>                    
                    </div>
                    <div class="gantt-task-info">
                        <span class="gantt-task-data-lp">{{ task.licensePlate}}</span>
                        <span class="gantt-task-data-ch"> · {{ task.caflerHash }}</span>
                    </div>
                </div>                               
            </div>
          </div>
         
        </div>
         
    </div>

    <ng-template #emptyGantt>
        <div class="d-flex flex-column align-items-center w-100 no-services-content">
            <div class="empty-home-page d-flex flex-column align-items-center">
                <img src="../../../assets/empty-gantt.svg" alt="Empty gantt section" />
                <h4>{{ 'noServicesToday' | translate }}</h4>
                <p class="text-center">{{ 'noServicesTodayDescription' | translate }}</p>
            </div>
        </div>
    </ng-template>

      
      
</div>