import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from './services/navigation.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    isCollapsed = false;
    @Output() isCollapsedEvent = new EventEmitter<boolean>(false);

    sections: any[];
    accountSections: any[];

    userName = 'Test User';
    userEmail = 'test@gmail.com';
    userImg = '../../assets/icons/navbar/nav-user-icon.svg';

    private userSubscription!: Subscription;

    constructor(
        private navService: NavigationService,
        private userService: UserService,
        private router: Router
    ) {
        this.sections = this.navService.getSections();
        this.accountSections = this.navService.getAccountSections();
    }

    ngOnInit(): void {
        // Subscribe to the user info observable to handle user data updates
        this.userSubscription = this.userService.getUserInfo().subscribe((user) => {
            if (user) {
                this.userName = user.name;
                this.userEmail = user.email;
                this.userImg = user.img;
            } else {
                // Reset to default values when there is no user info (e.g., user logged out)
                this.userName = 'Guest User';
                this.userEmail = 'No Email Provided';
                this.userImg = '../../assets/icons/navbar/nav-user-icon.svg';
            }
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe to prevent memory leaks
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    toggleSidebar() {
        this.isCollapsed = !this.isCollapsed;
        this.isCollapsedEvent.emit(this.isCollapsed);
    }

    checkSamePath(subSectionPath: string): boolean {
        const actualPath = this.router.url;
        const segments = actualPath.split('/');
        const lastSegment = segments[segments.length - 1];
        return lastSegment === subSectionPath;
    }

    clearSummaryData() {
        localStorage.removeItem('SummaryData');
        localStorage.removeItem('returnTransferSummaryData');
    }
}
