import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-refueling-fee',
  templateUrl: './modal-refueling-fee.component.html',
  styleUrls: ['./modal-refueling-fee.component.scss']
})
export class ModalRefuelingFeeComponent {

}
