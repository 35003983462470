// auth.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MsalModule,
    MSAL_INSTANCE,
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
    MsalService,
    MsalGuard,
    MsalInterceptor,
} from '@azure/msal-angular';
import { MsalConfigService } from './msal-config.service';

@NgModule({
    imports: [CommonModule, MsalModule],
    providers: [
        {
            provide: MSAL_INSTANCE,
            useFactory: MsalConfigService.createMsalInstance,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MsalConfigService.msalGuardConfig,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MsalConfigService.msalInterceptorConfig,
        },
        MsalService,
        MsalGuard,
        MsalInterceptor,
    ],
})
export class AuthModule {}
