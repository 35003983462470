<div class="header">
    <img
        (click)="onClose()"
        (keyup)="onClose()"
        tabindex="0"
        src="/assets/icons/close-modal.svg"
        alt="closeIcon"
        class="pointer"
    />
</div>

<section>
    <div class="title">
        <h3>{{ 'wash' | translate }}</h3>
        <p>{{ 'wash_topping_description' | translate }}</p>
    </div>
    <div class="content-box" *ngIf="tunnelWashProducts">
        <div class="text">
            <h4>{{ 'tunnel_wash' | translate }}</h4>
            <p>{{ 'tunnel_wash_description' | translate }}</p>
        </div>
        <div class="products">
            <div *ngFor="let product of tunnelWashProducts">
                <div class="d-flex justify-content-between flex-row">
                    <div class="title-box">
                        <img [src]="product.icon" alt="{{ product.titleId }}" />
                        <p>{{ product.titleId }}</p>
                    </div>
                    <form [formGroup]="form">
                        <label class="switch">
                            <input
                                type="checkbox"
                                [formControlName]="product.productKey"
                                (change)="onToggleChange(product.productKey, $event)"
                            />
                            <span class="slider round"></span>
                        </label>
                    </form>
                </div>

                <!-- <div class="time-box">
                    <img src="../../../../../../../assets/icons/extras-modal/washing/clock.svg" alt="reloj icon" />
                    <span>{{ product.time }}</span>
                </div> -->
                <p>{{ product.descriptionId }}</p>
                <span>+ {{ product.price }}€</span>
            </div>
        </div>
    </div>
    <div class="content-box" *ngIf="specializedCenterWashProducts">
        <div class="text">
            <h4>{{ 'specialized_center_wash' | translate }}</h4>
            <p>{{ 'specialized_center_wash_description' | translate }}</p>
        </div>
        <div class="products">
            <div *ngFor="let product of specializedCenterWashProducts">
                <div class="d-flex justify-content-between flex-row">
                    <div class="title-box">
                        <img [src]="product.icon" alt="{{ product.titleId }}" />
                        <p>{{ product.titleId }}</p>
                    </div>
                    <form [formGroup]="form">
                        <label class="switch">
                            <input
                                type="checkbox"
                                [formControlName]="product.productKey"
                                (change)="onToggleChange(product.productKey, $event)"
                            />
                            <span class="slider round"></span>
                        </label>
                    </form>
                </div>

                <p>{{ product.descriptionId }}</p>
                <span>+{{ product.price }}€</span>
            </div>
        </div>
    </div>
    <div class="footer">
        <button (click)="onAdd()">{{ 'add' | translate }}</button>
    </div>
</section>
