

<span class="badge gray-badge" *ngIf="statusValue === vhicleStatusEnum.Unknown">
    <span class="circle-gray"></span>
    {{'no-status' | translate}}
</span>

<span class="badge green-badge" *ngIf="statusValue === vhicleStatusEnum.Active">
    <span class="circle-green"></span>
    {{'active' | translate}}
</span>

<span class="badge red-badge" *ngIf="statusValue === vhicleStatusEnum.Inactive">
    <span class="circle-red"></span>
    {{'inactive' | translate}}
</span>
