import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    private showAlertSubject = new BehaviorSubject<boolean>(false);
    showAlert$ = this.showAlertSubject.asObservable();

    private showOnceFlag = false;

    setShowAlert(value: boolean) {
        this.showAlertSubject.next(value);
        if (value) {
            this.showOnceFlag = true;
        }
    }

    shouldShowAlert(): boolean {
        if (this.showOnceFlag) {
            this.showOnceFlag = false;
            return true;
        }
        return false;
    }

    resetAlert() {
        this.showAlertSubject.next(false);
        this.showOnceFlag = false;
    }
}
