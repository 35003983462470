import { Component, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, AuthError } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';
import { LoaderInterceptor } from './core/interceptor/loader.interceptor';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'cafler-frontend';
    checkIsCollapsed = false;

    constructor(
        private authService: MsalService,
        private router: Router,
        private userService: UserService,
        private msalBroadcastService: MsalBroadcastService,
        private loaderInterceptor: LoaderInterceptor
    ) {}

    ngOnInit() {
        this.msalBroadcastService.inProgress$.pipe(filter((status) => status === InteractionStatus.None)).subscribe({
            next: () => {
                if (this.authService.instance.getAllAccounts().length > 0) {
                    this.userService.refreshUserInfo();
                } else {
                    this.router.navigate(['/login']);
                }
            },
        });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                )
            )
            .subscribe({
                next: (msg: EventMessage) => {
                    // this.router.navigate(['/login']);
                },
                error: (error) => console.error(error),
            });

        this.authService.instance.addEventCallback((event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_FAILURE && event.error) {
                const error = event.error as AuthError;
                if (error.errorCode === 'access_denied' && error.errorMessage.includes('AADB2C90091')) {
                    this.router.navigate(['/login']);
                }
            }
        });
    }

    changeCheck(newItem: boolean) {
        this.checkIsCollapsed = newItem;
    }
}
