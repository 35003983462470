import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() sectionName: string | undefined = '';
    @Input() isHrTransparent: boolean | undefined = false;

    titulo = 'Hola, ';
    usuario = 'Guest';
    private userSubscription!: Subscription;
    backButton = false;

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.userSubscription = this.userService.getUserInfo().subscribe((userInfo) => {
            if (userInfo) {
                this.usuario = userInfo.name ?? 'Guest';
            } else {
                this.usuario = 'Guest';
            }
        });

        this.route.data.subscribe((data) => {
            if (data['title']) {
                if (data['titleKey'] === 'homeTitle') {
                    this.sectionName = `${this.translate.instant('hello')}, ${this.usuario}`;
                } else {
                    this.sectionName = data['title'];
                }
            }

            if (data['backArrow']) {
                this.backButton = true;
            }
        });
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }

    backButtonAction(): void {
        window.history.back();
    }
}
