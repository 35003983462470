<div class="alert-container alert-container-sm" [ngClass]="{ 'alert-container-sm': isForDialog }">
  <div class="d-flex">
    <div
      id="customAlert"
      class="alert success d-flex flex-row"
      *ngIf="type === 1"
      [ngClass]="{ 'alert-sm': isForDialog }"
    >
      <span> <img src="../../../assets/icons/check-circle.svg" alt="" /></span>
      <div class="flex-grow-1">{{ text }}</div>
      <button
        id="closeAlert"
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="closeAlert()"
      ></button>
    </div>

    <div
      id="customAlert"
      class="alert alert-sm error d-flex flex-row"
      *ngIf="type === 2"
      [ngClass]="{ 'alert-sm': isForDialog }"
    >
      <span> <img src="../../../assets/icons/x-circle.svg" alt="" /></span>
      <div class="flex-grow-1">{{ text }}</div>
      <button
        id="closeAlert"
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="closeAlert()"
      ></button>
    </div>
  </div>
</div>
