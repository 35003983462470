export const serviceType: { [key: number]: string } = {
    1: 'Transfer',
    2: 'Taller y mantenimiento',
    3: 'Lavado',
    4: 'Repostaje',
    5: 'Pre-ITV',
    6: 'medium_large_transfer',
    7: 'ITV',
    8: 'Seguro',
    9: 'Comprobación del estado del vehículo',
    10: 'Cafler Fresh',
    11: 'Valet',
    12: 'Neumáticos',
    13: 'Trámites',
    14: 'Parking',
    15: 'Vehículo de sustitución',
    16: 'Grúa',
    17: 'Pide lo que necesites',
};
