<div class="header">
    <img
        (click)="onClose()"
        (keyup)="onClose()"
        tabindex="0"
        src="/assets/icons/close-modal.svg"
        alt="closeIcon"
        class="pointer"
    />
</div>
<div class="refill-box">
    <app-alert
        [text]="errorAlertText"
        [type]="2"
        [isForDialog]="true"
        *ngIf="showErrorAlert"
        (closeAlertEvent)="onAlertClosed()"
    ></app-alert>
    <div class="title-box">
        <h4>{{ 'refueling' | translate }}</h4>
        <p>{{ 'refueling_description' | translate }}</p>
    </div>
    <div class="seleccion-importe">
        <h4>Selecciona el importe a repostar</h4>
        <div>
            <span class="price">+4,99{{ 'currency_symbol' | translate }}</span> (I.V.A incluido) de suplemento
        </div>
    </div>
    <div class="prices-box">
        <span class="title" *ngIf="fuelTypeOptions.length > 0">{{ 'whichFuelTypeQuestion' | translate }}</span>

        <form [formGroup]="refillForm" class="w-100" *ngIf="fuelTypeOptions.length > 0">
            <div class="d-flex fuel-type-options w-100">
                <div
                    *ngFor="let fuelTypeOption of fuelTypeOptions"
                    [ngClass]="{ selected: selectedFuelType === fuelTypeOption.optionId }"
                    (click)="selectFuelType(fuelTypeOption)"
                    (keydown)="selectFuelType(fuelTypeOption)"
                    tabindex="0"
                    class="align-items-center d-flex justify-content-between option w-100"
                >
                    <div class="d-flex content">
                        <img
                            [src]="
                                selectedFuelType === fuelTypeOption.optionId
                                    ? 'assets/icons/active-routes-check.svg'
                                    : 'assets/icons/active-routes-uncheck.svg'
                            "
                            alt=""
                            [ngClass]="{ 'image-checked': selectedFuelType === fuelTypeOption.optionId }"
                        />
                        <span>
                            {{ fuelTypeOption.description | translate }}
                        </span>
                    </div>
                    <div *ngIf="fuelTypeOption.premium" class="premium-box d-flex">
                        <img src="assets/icons/star.svg" alt="" />
                        <span>{{ 'premium' | translate | uppercase }}</span>
                    </div>
                </div>
            </div>
        </form>

        <span class="title">{{ 'whichMonetaryAmountQuestion' | translate }}</span>
        <div class="d-flex monetary-amount-options">
            <div
                *ngFor="let amount of monetaryAmounts; let i = index"
                [ngClass]="{ checked: selectedMonetaryAmount === i + 1 }"
                (click)="selectMonetaryAmount(i + 1)"
                (keydown)="selectMonetaryAmount(i + 1)"
                tabindex="0"
            >
                <span class="price">{{ monetaryAmounts[i] }}{{ 'currency_symbol' | translate }}</span>
                <span class="subtitle">Suplido exento de I.V.A.</span>
            </div>
        </div>
    </div>
    <div class="info-box">
        <img src="/assets/icons/information-circle.svg" alt="Info icon" />
        <p>{{ 'refueling_disclaimer' | translate }}.</p>
    </div>
    <div class="footer">
        <button (click)="addTopping()">{{ 'add' | translate }}</button>
    </div>
</div>
