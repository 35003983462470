import { IProduct } from '../interfaces/product.interface';

export const products: { [key: string]: IProduct } = {
  transfer: {
    productIcon: '../../../../assets/icons/info-modal/transfer/productIcon.svg',
    title: 'Transfer',
    description: 'transfer_point_A_to_B',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'transfer_substitution_vehicle_premise',
      'transfer_modal_info',
      'transfer_assign_a_professional_driver',
      'transfer_safely_transport_your_vehicle',
      'transfer_deliver_your_vehicle_at_agreed_point',
    ],
    productImage: '../../../../assets/icons/info-modal/transfer/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  transit: {
    productIcon: '../../../../assets/icons/info-modal/transfer/transit.svg',
    title: 'transit_transfer',
    description: 'transit_transfer_info_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'indicate_transfer_detail',
      'budget_detail_receivement',
      'transfer_assign_a_professional_driver',
      'transfer_safely_transport_your_vehicle',
      'transfer_deliver_your_vehicle_at_agreed_point',
    ],
    productImage: '../../../../assets/icons/info-modal/transfer/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'transfer-replacement-vehicle': {
    productIcon: '../../../../assets/icons/info-modal/transfer/transit.svg',
    title: 'replacement_vehicle',
    description: 'replacement_vehicle_catalog_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'provide_transfer_details_select_date_time',
      'receive_confirmation_and_details',
      'transfer_assign_a_professional_driver',
      'transfer_safely_transport_your_vehicle',
      'transfer_deliver_your_vehicle_at_agreed_point',
    ],
    productImage: '../../../../assets/icons/info-modal/transfer/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'return-transfer': {
    productIcon: '../../../../assets/icons/info-modal/transfer/productIcon.svg',
    title: 'return_transfer',
    description: 'return_transfer_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'return_transfer_pre_requesite',
      'transfer_assign_a_professional_driver',
      'transfer_safely_transport_your_vehicle',
      'transfer_deliver_your_vehicle_at_agreed_point',
    ],
    productImage: '../../../../assets/icons/info-modal/transfer/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'transfer-long-distance-out-of-range': {
    productIcon: '../../../../assets/icons/info-modal/transfer/productIcon.svg',
    title: 'long_distance_transfer_out_of_range',
    description: 'transfer_point_A_to_B',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'provide_transfer_details_request_quote',
      'receive_quote_booking_transfer_details',
      'transfer_assign_a_professional_driver',
      'transfer_safely_transport_your_vehicle',
      'transfer_deliver_your_vehicle_at_agreed_point',
    ],
    productImage: '../../../../assets/icons/info-modal/transfer/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'medium-long-distance': {
    productIcon: '../../../../assets/icons/info-modal/medium-long-distance/productIcon.svg',
    title: 'long_distance_tranfer',
    description: 'transfer_point_A_to_B',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'transfer_substitution_vehicle_premise',
      'transfer_modal_info',
      'transfer_assign_a_professional_driver',
      'transfer_safely_transport_your_vehicle',
      'transfer_deliver_your_vehicle_at_agreed_point',
    ],
    productImage: '../../../../assets/icons/info-modal/transfer/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'technical-inspection': {
    productIcon: '../../../../assets/icons/info-modal/ITV/productIcon.svg',
    title: 'ITV',
    description: 'itv_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/pre-itv/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_itv_service',
      'receive_confirmation_and_details',
      'assign_itv_specialist_driver',
      'transport_vehicle_for_pre_itv',
      'perform_itv_if_requested',
      'deliver_vehicle_ready_to_drive',
    ],
    productImage: '../../../../assets/icons/info-modal/ITV/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'mot-fuel-car-es': {
    productIcon: '../../../../assets/icons/info-modal/ITV/productIcon.svg',
    title: 'ITV',
    description: 'itv_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/pre-itv/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_itv_service',
      'receive_confirmation_and_details',
      'assign_itv_specialist_driver',
      'transport_vehicle_for_pre_itv',
      'perform_itv_if_requested',
      'deliver_vehicle_ready_to_drive',
    ],
    productImage: '../../../../assets/icons/info-modal/ITV/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'mot-diesel-car-es': {
    productIcon: '../../../../assets/icons/info-modal/ITV/productIcon.svg',
    title: 'ITV',
    description: 'itv_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/pre-itv/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_itv_service',
      'receive_confirmation_and_details',
      'assign_itv_specialist_driver',
      'transport_vehicle_for_pre_itv',
      'perform_itv_if_requested',
      'deliver_vehicle_ready_to_drive',
    ],
    productImage: '../../../../assets/icons/info-modal/ITV/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  '360-mot-fuel-car-es': {
    productIcon: '../../../../assets/icons/info-modal/ITV/productIcon.svg',
    title: '360_mot',
    description: 'itv_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/pre-itv/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_itv_service',
      'receive_confirmation_and_details',
      'assign_itv_specialist_driver',
      'transport_vehicle_for_pre_itv',
      'perform_itv_if_requested',
      'deliver_vehicle_ready_to_drive',
    ],
    productImage: '../../../../assets/icons/info-modal/ITV/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  '360-mot-diesel-car-es': {
    productIcon: '../../../../assets/icons/info-modal/ITV/productIcon.svg',
    title: '360_mot',
    description: 'itv_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/pre-itv/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_itv_service',
      'receive_confirmation_and_details',
      'assign_itv_specialist_driver',
      'transport_vehicle_for_pre_itv',
      'perform_itv_if_requested',
      'deliver_vehicle_ready_to_drive',
    ],
    productImage: '../../../../assets/icons/info-modal/ITV/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'mot-homologation': {
    productIcon: '../../../../assets/icons/info-modal/ITV/productIcon.svg',
    title: 'Homologación ITV',
    description: 'mot_homologation_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/pre-itv/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
    ],
    howItWorksDescription: [
      'choose_registration_type',
      'select_convenient_time',
      'professional_driver_collects_documents',
      'take_vehicle_to_itv_center',
      'return_vehicle_after_inspection',
      'itv_center_updates_documents',
    ],
    productImage: '../../../../assets/icons/info-modal/ITV/mot-homologation.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'hand-wash': {
    productIcon: '../../../../assets/icons/info-modal/hand-wash/productIcon.svg',
    title: 'hand_wash',
    description: 'hand_wash_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/specialized-center-wash/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_wash_type',
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_vehicle_to_wash_center',
      'perform_service_leaving_vehicle_as_new',
      'deliver_clean_and_shiny_vehicle',
    ],
    productImage: '../../../../assets/icons/info-modal/hand-wash/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'specialized-center-wash': {
    productIcon: '../../../../assets/icons/info-modal/specialized-center-wash/productIcon.svg',
    title: 'specialized_centers',
    description: 'specialized_centers_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/specialized-center-wash/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_wash_type',
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_vehicle_to_wash_center',
      'perform_service_leaving_vehicle_as_new',
      'deliver_clean_and_shiny_vehicle',
    ],
    productImage: '../../../../assets/icons/info-modal/specialized-center-wash/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'tunnel-motorbike-wash': {
    productIcon: '../../../../assets/icons/info-modal/specialized-center-wash/productIcon.svg',
    title: 'specialized_centers',
    description: 'specialized_centers_description',
    isIncluded: true,
    includingDescriptions: [
      'fairing_and_seat_upholstery_cleaning',
      'chassis_and_mechanical_parts_cleaning',
    ],
    includingDescriptionsListed: true,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/specialized-center-wash/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_vehicle_to_wash_center',
      'perform_service_leaving_vehicle_as_new',
      'deliver_clean_and_shiny_vehicle',
    ],
    productImage:
      '../../../../assets/icons/info-modal/specialized-center-wash/tunnel-motorbike-wash.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'upholstery-detail-wash-5-seats': {
    productIcon:
      '../../../../assets/icons/info-modal/upholstery-detail-wash-5-seats/productIcon.svg',
    title: 'upholsteries',
    description: 'upholsteries_description',
    isIncluded: true,
    includingDescriptions: ['special_vacuuming_of_upholstery', 'cleaning_of_each_seat_in_vehicle'],
    includingDescriptionsListed: true,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/headlight-polishing/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'schedule_day_and_hour_for_your_service',
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_vehicle_to_wash_center',
      'perform_service_leaving_vehicle_as_new',
      'deliver_clean_and_shiny_vehicle',
    ],
    productImage:
      '../../../../assets/icons/info-modal/upholstery-detail-wash-5-seats/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'integral-washing': {
    productIcon: '../../../../assets/icons/info-modal/integral-washing/productIcon.svg',
    title: 'integral',
    description: 'integral_description',
    isIncluded: true,
    includingDescriptions: [
      'deep_cleaning_of_body_wheels_and_wheel_wells',
      'cleaning_of_exterior_and_interior_windows',
      'application_of_protective_wax',
      'trunk_cleaning',
      'deep_vacuuming_and_cleaning_of_interior_including_carpets_and_rugs',
      'cleaning_of_doors_frames_and_interior_plastics',
      'cleaning_of_dashboard_and_center_console',
      'complete_disinfection_and_bright_finish_of_vehicle',
    ],
    includingDescriptionsListed: true,

    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/headlight-polishing/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'schedule_day_and_hour_for_your_service',
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_vehicle_to_wash_center',
      'perform_service_leaving_vehicle_as_new',
      'deliver_clean_and_shiny_vehicle',
    ],
    productImage: '../../../../assets/icons/info-modal/integral-washing/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'tunnel-complete-car-wash': {
    productIcon: '../../../../assets/icons/info-modal/specialized-center-wash/productIcon.svg',
    title: 'tunnel_complete_wash',
    description: 'experience_the_ultimate_clean',
    isIncluded: true,
    includingDescriptions: [
      'hand_wash_body_windows_doors',
      'tunnel_drying',
      'dashboard_console_cleaning',
      'clean_windows_plastics_doors',
      'vacuuming_cleaning_trunk',
    ],
    includingDescriptionsListed: true,
    productImage:
      '../../../../assets/icons/info-modal/specialized-center-wash/specialized-center-complete.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'tunnel-exterior-car-wash': {
    productIcon: '../../../../assets/icons/washing-specialist-center-outside.svg',
    title: 'tunnel_exterior_wash',
    description: 'give_your_vehicle_the_shine_it_deserves',
    isIncluded: true,
    includingDescriptions: ['hand_wash_body_windows_doors', 'tunnel_drying'],
    includingDescriptionsListed: true,
    productImage:
      '../../../../assets/icons/info-modal/specialized-center-wash/specialized-center-exterior.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'tunnel-interior-car-wash': {
    productIcon: '../../../../assets/icons/washing-specialist-center-inside.svg',
    title: 'tunnel_interior_wash',
    description: 'tunnel_interior_wash_description',
    isIncluded: true,
    includingDescriptions: [
      'vacuuming_floor_mats_carpets_seats',
      'dashboard_console_cleaning',
      'clean_windows_plastics_doors',
      'vacuuming_cleaning_trunk',
    ],
    includingDescriptionsListed: true,
    productImage:
      '../../../../assets/icons/info-modal/specialized-center-wash/specialized-center-interior.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'headlight-polishing': {
    productIcon: '../../../../assets/icons/info-modal/headlight-polishing/productIcon.svg',
    title: 'headlight_polishing',
    description: 'headlight_polishing_description',
    isIncluded: true,
    includingDescriptions: [
      'front_headlight_polishing',
      'restore_total_transparency_to_headlights_and_glass',
      'specific_machines_for_polishing_and_polymer',
    ],
    includingDescriptionsListed: true,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/headlight-polishing/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'schedule_day_and_hour_for_your_service',
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_vehicle_to_wash_center',
      'perform_service_leaving_vehicle_as_new',
      'deliver_clean_and_shiny_vehicle',
    ],
    productImage: '../../../../assets/icons/info-modal/headlight-polishing/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'maintenance-pack': {
    productIcon: '../../../../assets/icons/info-modal/maintenance-pack/productIcon.svg',
    title: 'maintenance_pack',
    description: 'maintenance_pack_description',
    isIncluded: true,
    includingDescriptions: [
      'oil_and_oil_filter_change',
      'air_filter_change',
      'inspection_of_more_than_30_check_points',
      'and_much_more',
    ],
    includingDescriptionsListed: true,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/maintenance-pack/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_between_basic_medium_or_full_pack',
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_your_vehicle_to_the_workshop',
      'perform_maintenance_and_provide_estimate_for_additional_repairs',
      'finally_deliver_your_vehicle',
    ],
    productImage: '../../../../assets/icons/info-modal/maintenance-pack/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'car-complete-maintenance': {
    productIcon: '../../../../assets/icons/info-modal/maintenance-pack/productIcon.svg',
    title: 'car_complete_maintenance',
    description: 'car_complete_maintenance_description',
    isIncluded: true,
    includingDescriptions: [
      'oil_and_oil_filter_change',
      'air_filter_change',
      'inspection_of_more_than_30_check_points',
    ],
    includingDescriptionsListed: true,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/maintenance-pack/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    productImage:
      '../../../../assets/icons/info-modal/maintenance-pack/complete-maintenance-pack.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'motorbike-complete-maintenance': {
    productIcon: '../../../../assets/icons/info-modal/maintenance-pack/productIcon.svg',
    title: 'car_complete_maintenance',
    description: 'car_complete_maintenance_description',
    isIncluded: true,
    includingDescriptions: [
      'oil_and_oil_filter_change',
      'air_filter_change',
      'fuel_filter_change',
      'inspection_of_more_than_30_check_points',
    ],
    includingDescriptionsListed: true,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/maintenance-pack/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    productImage:
      '../../../../assets/icons/info-modal/maintenance-pack/complete-maintenance-pack.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'car-basic-maintenance': {
    productIcon: '../../../../assets/icons/info-modal/maintenance-pack/productIcon.svg',
    title: 'car_basic_maintenance',
    description: 'car_basic_maintenance_description',
    isIncluded: true,
    includingDescriptions: [
      'oil_and_oil_filter_change',
      'air_filter_change',
      'inspection_of_more_than_30_check_points',
    ],
    includingDescriptionsListed: true,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/maintenance-pack/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    productImage: '../../../../assets/icons/info-modal/maintenance-pack/basic-maintenance-pack.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'motorbike-basic-maintenance': {
    productIcon: '../../../../assets/icons/info-modal/maintenance-pack/productIcon.svg',
    title: 'car_basic_maintenance',
    description: 'car_basic_maintenance_description',
    isIncluded: true,
    includingDescriptions: [
      'oil_and_oil_filter_change',
      'air_filter_change',
      'inspection_of_more_than_30_check_points',
    ],
    includingDescriptionsListed: true,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/maintenance-pack/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    productImage: '../../../../assets/icons/info-modal/maintenance-pack/basic-maintenance-pack.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'car-medium-maintenance': {
    productIcon: '../../../../assets/icons/info-modal/maintenance-pack/productIcon.svg',
    title: 'car_medium_maintenance',
    description: 'car_medium_maintenance_description',
    isIncluded: true,
    includingDescriptions: [
      'oil_and_oil_filter_change',
      'air_filter_change',
      'inspection_of_more_than_30_check_points',
    ],
    includingDescriptionsListed: true,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/maintenance-pack/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    productImage:
      '../../../../assets/icons/info-modal/maintenance-pack/medium-maintenance-pack.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'refueling-car-topping': {
    productIcon: '../../../../assets/icons/info-modal/refueling-car-topping/productIcon.svg',
    title: 'refueling',
    description: 'refueling_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/refueling-car-topping/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_day_time_and_amount_to_refuel',
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_your_vehicle_to_the_gas_station',
      'fill_the_tank_with_chosen_amount',
      'finally_deliver_your_vehicle',
    ],
    productImage: '../../../../assets/icons/info-modal/refueling-car-topping/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_fuel',
    ],
  },
  refueling: {
    productIcon: '../../../../assets/icons/info-modal/refueling-car-topping/productIcon.svg',
    title: 'refueling',
    description: 'refueling_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/refueling-car-topping/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_day_time_and_amount_to_refuel',
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_your_vehicle_to_the_gas_station',
      'fill_the_tank_with_chosen_amount',
      'finally_deliver_your_vehicle',
    ],
    productImage: '../../../../assets/icons/info-modal/refueling-car-topping/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_fuel',
    ],
  },
  'car-service': {
    productIcon: '../../../../assets/icons/info-modal/car-service/productIcon.svg',
    title: 'diagnosis',
    description: 'diagnostic_catalog_description',
    tinyAditionalInformation: 'tow_truck_additional_info',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/car-service/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_day_and_time_for_service',
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_your_vehicle_to_the_workshop_for_diagnosis',
      'inform_you_after_diagnosis_and_provide_free_estimate',
      'finally_deliver_your_vehicle_with_relevant_repairs',
    ],
    productImage: '../../../../assets/icons/info-modal/car-service/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_fuel',
    ],
  },
  'pre-mot': {
    productIcon: '../../../../assets/icons/info-modal/pre-itv/productIcon.svg',
    title: 'pre_mot',
    description: 'pre_itv_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/pre-itv/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_pre_itv_service',
      'receive_confirmation_and_details',
      'assign_pre_itv_specialist_driver',
      'transport_your_vehicle_to_the_workshop_for_pre_itv',
      'perform_itv_if_requested',
      'finally_deliver_your_vehicle_ready_to_drive',
    ],
    productImage: '../../../../assets/icons/info-modal/pre-itv/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  '360-pre-mot': {
    productIcon: '../../../../assets/icons/info-modal/pre-itv/productIcon.svg',
    title: '360_pre_mot',
    description: 'pre_itv_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/pre-itv/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_pre_itv_service',
      'receive_confirmation_and_details',
      'assign_pre_itv_specialist_driver',
      'transport_your_vehicle_to_the_workshop_for_pre_itv',
      'perform_itv_if_requested',
      'finally_deliver_your_vehicle_ready_to_drive',
    ],
    productImage: '../../../../assets/icons/info-modal/pre-itv/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  tyres: {
    productIcon: '../../../../assets/icons/info-modal/tyres/productIcon.svg',
    title: 'tyres',
    description: 'tyres_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tyres/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/tyres/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_your_tires_complete_your_details_and_receive_your_quote',
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_your_vehicle_to_the_workshop',
      'perform_tire_change',
      'deliver_your_vehicle_with_new_tires',
    ],
    productImage: '../../../../assets/icons/info-modal/tyres/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'fine-tunning': {
    productIcon: '../../../../assets/icons/info-modal/fine-tunning/productIcon.svg',
    title: 'fine_tunning',
    description: 'fine_tunning_description',
    tinyAditionalInformation: 'tow_truck_additional_info',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/fine-tunning/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_day_and_time_for_your_service',
      'receive_confirmation_and_details_of_selected_service',
      'assign_specialized_driver',
      'transport_your_vehicle_to_the_workshop',
      'perform_tune_up_and_inform_if_special_intervention_is_needed',
      'finally_deliver_your_vehicle',
    ],
    productImage: '../../../../assets/icons/info-modal/fine-tunning/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  parking: {
    productIcon: '../../../../assets/icons/info-modal/parking/productIcon.svg',
    title: 'Parking',
    description: 'parking_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/parking/howItWorksIcon1.svg',
      '',
    ],
    howItWorksDescription: [
      'find_your_ideal_parking_spot',
      'reserve_parking_space_online_no_cancellation_fees',
      'guaranteed_parking_spot_relax_your_car_is_in_good_hands',
    ],
    productImage: '../../../../assets/icons/info-modal/parking/productImage.png',
    isRequested: true,
    requestedIcon: ['../../../../assets/icons/info-modal/valet/requestedIcon1.svg'],
    requestedDescription: ['parking_premises'],
  },
  'valet-station': {
    productIcon: '../../../../assets/icons/info-modal/valet/productIcon.svg',
    title: 'Valet',
    description: 'valet_description',
    aditionalInformation: 'valet_additional_info',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'reserve_exact_day_and_time_15_minute_slot',
      'receive_confirmation_and_service_details',
      'driver_will_contact_before_pickup_to_confirm_meeting_point',
      'we_will_pick_up_your_vehicle_and_store_it_at_nearest_parking',
      'driver_will_contact_before_return_to_arrange_meeting_point',
    ],
    productImage: '../../../../assets/icons/info-modal/valet/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  'ownership-change': {
    productIcon: '../../../../assets/icons/info-modal/ownership-change/productIcon.svg',
    title: 'ownership_change',
    description: 'ownership_change_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'provide_vehicle_data_to_show_service_price',
      'receive_confirmation_and_service_details_via_email',
      'email_will_detail_steps_to_follow_for_service',
    ],
    productImage: '../../../../assets/icons/info-modal/ownership-change/productImage.png',
    isRequested: false,
  },
  tow_truck: {
    productIcon: '../../../../assets/icons/info-modal/tow/productIcon.svg',
    title: 'tow_truck',
    description: 'tow_truck_info_description',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'indicate_origin_destination_and_preferred_pickup_time',
      'contact_you_with_fixed_quote_and_preferred_date_time',
      'confirm_service_and_coordination_after_accepting_quote',
    ],
    productImage: '../../../../assets/icons/info-modal/tow/productImage.png',
    isRequested: false,
  },
  'open-form': {
    productIcon: '../../../../assets/icons/info-modal/ask-everything/productIcon.svg',
    title: 'open_form',
    description: 'can_find_what_you_need',
    isIncluded: false,
    howItWorksPlain: 'open_form_description',
    howItWorksDescriptionStrong: 'open_form_contact_with_customer',
    productImage: '../../../../assets/icons/info-modal/ask-everything/productImage.png',
    isRequested: false,
  },
  replacement_vehicle: {
    productIcon: '../../../../assets/icons/sustitution-vehicle.svg',
    title: 'replacement_vehicle',
    description: 'replacement_vehicle_description',
    isIncluded: false,
    conditions: [
      {
        title: 'vehicle_type_title',
        content: 'vehicle_type_content',
      },
      {
        title: 'driver',
        content: 'driver_content',
      },
      {
        title: 'payment_title',
        content: 'payment_content',
      },
      {
        title: 'schedule',
        content: 'schedule_content',
      },
      {
        title: 'duration',
        content: 'duration_content',
      },
      {
        title: 'insurance_mileage_title',
        content: 'insurance_mileage_content',
      },
      {
        title: 'requirements_title',
        content: 'requirements_content',
      },
      {
        title: 'deposit_title',
        content: 'deposit_content',
      },
    ],
    productImage: '../../../../assets/icons/info-modal/ITV/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  wash: {
    productIcon: '../../../../assets/icons/lavados-icon.svg',
    title: 'wash',
    description: 'washDescription',
    isIncluded: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon1.svg',
      '../../../../assets/icons/info-modal/valet/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'select_wash_type',
      'receive_confirmation_and_details',
      'assign_specialized_driver',
      'transport_vehicle_to_wash_center',
      'deliver_clean_and_shiny_vehicle',
    ],
    productImage: '../../../../assets/icons/info-modal/wash-topping/wash-topping.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  checkStatusVehicle: {
    productIcon: '../../../../assets/icons/check-status.svg',
    title: 'checkStatusVehicle',
    description: 'check_status_vehicle_description',
    isIncluded: true,
    includingDescriptions: [
      'oil_level_check',
      'wiper_blades_check',
      'windshield_washer_fluid_check',
      'coolant_and_anti_freeze_level_check',
      'tire_wear_check',
      'tire_pressure_check',
      'lights_activated_check',
      'lights_and_other_faults_check',
    ],
    includingDescriptionsListed: true,
    productImage: '../../../../assets/icons/info-modal/ITV/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
  },
  insurance: {
    productIcon: '../../../../assets/icons/insurance-during-service.svg',
    title: 'insurance',
    description: 'insurance_description',
    isIncluded: false,
    includingDescriptions: ['insurance_including_description'],
    includingDescriptionsListed: false,
    howItWorksIcon: [
      '../../../../assets/icons/info-modal/tow/howItWorksIcon2.svg',
      '../../../../assets/icons/info-modal/insurance-topping/insurance-prerequisites.svg',
      '../../../../assets/icons/info-modal/tow/howItWorksIcon3.svg',
    ],
    howItWorksDescription: [
      'indicate_insurance_days_needed',
      'enter_vehicle_owner_details',
      'vehicle_ready_for_use',
    ],
    productImage: '../../../../assets/icons/info-modal/ITV/productImage.png',
    isRequested: true,
    requestedIcon: [
      '../../../../assets/icons/info-modal/valet/requestedIcon1.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon2.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon3.svg',
      '../../../../assets/icons/info-modal/valet/requestedIcon4.svg',
    ],
    requestedDescription: [
      'driver_license',
      'technical_sheet',
      'valid_insurance_policy',
      'minimum_quarter_tank',
    ],
    isAxa: true,
  },
};
