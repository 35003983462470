import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ProductCatalogResponse } from 'src/app/models/category.model';
import { environment } from 'src/environments/environment';
import { PersonalizationId } from '../components/new-service-personalization/new-service-personalization.component';
import { ServiceConfiguration, SummaryData } from '../interfaces/SummaryData';
import { VehicleInformationResponse } from '../interfaces/VehicleInformationResponse';
import {
    BookOpenForm,
    BookRequest,
    ProductAvailabilityParams,
    ProductCatalogParams,
} from './interfaces/funnel.interface';
import * as moment from 'moment-timezone';
@Injectable({
    providedIn: 'root',
})
export class FunnelService {
    private productsUrl = environment.products_url;
    private token: string | undefined;

    constructor(
        private http: HttpClient,
        private router: Router,
        private authService: AuthService
    ) {
        this.initializeToken();
    }

    private async initializeToken(): Promise<void> {
        try {
            const token = await this.authService.getAccessToken();
            if (!token) {
                console.error('Unable to obtain access token during initialization');
            }
            this.token = token;
        } catch (error) {
            console.error('Error getting access token during initialization:', error);
        }
    }

    private async ensureToken(): Promise<string> {
        if (!this.token) {
            await this.initializeToken();
        }
        if (!this.token) {
            throw new Error('Unable to obtain access token');
        }
        return this.token;
    }

    private ensureTokenObservable(): Observable<string> {
        return from(this.ensureToken());
    }

    private getHeaders(): Observable<HttpHeaders> {
        return this.ensureTokenObservable().pipe(
            map(
                (token) =>
                    new HttpHeaders({
                        Authorization: `Bearer ${token}`,
                        'Cafler-User-Agent': 'CaflerFleetManager/1.0',
                    })
            )
        );
    }

    addTopping(productId: string, orderHash: string, serviceConfiguration: ServiceConfiguration, toppingType: string) {
        return this.getHeaders().pipe(
            switchMap((headers) => {
                let body: any;

                switch (toppingType) {
                    case 'refill':
                        body = {
                            ProductId: productId,
                            ServiceConfiguration: JSON.stringify({
                                MonetaryAmount: serviceConfiguration.monetaryAmount,
                                FuelType: serviceConfiguration.fuelType,
                            }),
                        };
                        break;
                    case 'insurance':
                    case 'accesory':
                    case 'replacement-vehicle':
                        body = {
                            ProductId: productId,
                            ServiceConfiguration: JSON.stringify(serviceConfiguration),
                        };
                        break;
                    case 'wash':
                    case 'topping-vehicle-data-collection':
                    case 'tyres':
                        body = {
                            ProductId: productId,
                        };
                        break;
                }

                return this.http.patch(`${this.productsUrl}/business/order/${orderHash}/topping`, body, {
                    headers,
                });
            }),
            catchError((error) => {
                console.error('Error adding topping:', error);
                throw error;
            })
        );
    }

    removeTopping(orderHash: string, productId?: string, removeAll?: boolean) {
        return this.getHeaders().pipe(
            switchMap((headers) => {
                const params: any = {};
                if (removeAll) {
                    params.removeAll = 'true';
                } else {
                    params.productId = productId;
                }

                return this.http.delete(`${this.productsUrl}/business/order/${orderHash}/topping`, {
                    params,
                    headers,
                });
            }),
            catchError((error) => {
                console.error('Error removing topping:', error);
                throw error;
            })
        );
    }

    getTechnicalInspectionStations(ZoneId: string) {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.get(`${this.productsUrl}/business/products/technical-inspection/station`, {
                    params: { ZoneId },
                    headers,
                })
            ),
            catchError((error) => {
                console.error('Error getting technical inspection stations:', error);
                throw error;
            })
        );
    }

    getValetStations(productId: string) {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.get(`${this.productsUrl}/info/stations`, {
                    params: { productId },
                    headers,
                })
            ),
            catchError((error) => {
                console.error('Error getting valet stations:', error);
                throw error;
            })
        );
    }

    getVehicleData(licensePlate: string) {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.get<VehicleInformationResponse>(`${this.productsUrl}/vehicle`, {
                    params: { licensePlate },
                    headers,
                })
            ),
            catchError((error) => {
                console.error('Error getting vehicle data:', error);
                throw error;
            })
        );
    }

    getProductCatalog(params: ProductCatalogParams) {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.get<ProductCatalogResponse>(`${this.productsUrl}/product-catalog`, {
                    params: { ...params },
                    headers,
                })
            ),
            catchError((error) => {
                console.error('Error getting product catalog:', error);
                throw error;
            })
        );
    }

    getProductBlockedDates(productId: string) {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.get(`${this.productsUrl}/blocked-dates`, {
                    params: { productId },
                    headers,
                })
            ),
            catchError((error) => {
                console.error('Error getting product blocked dates:', error);
                throw error;
            })
        );
    }

    getProductAvailability(params: ProductAvailabilityParams) {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.get(`${this.productsUrl}/availabilities`, {
                    params: { ...params },
                    headers,
                })
            ),
            catchError((error) => {
                console.error('Error getting product availability:', error);
                throw error;
            })
        );
    }

    // El método bookService se mantiene como estaba para los POST
    bookService(
        serviceType: string,
        summaryData: SummaryData,
        userInfo: string,
        isSimulation = false
    ): Observable<any> {
        return this.getHeaders().pipe(
            switchMap((headers) => {
                const dto = isSimulation ? this.dtoSimulation(summaryData) : this.dtoBookRequest(summaryData, userInfo);
                const endpoint = isSimulation
                    ? `${this.productsUrl}/business/products/${serviceType}/simulation`
                    : `${this.productsUrl}/business/products/${serviceType}`;

                return this.http.post(endpoint, dto, { headers });
            }),
            catchError((error) => {
                console.error(`Error ${isSimulation ? 'simulating' : 'booking'} ${serviceType}:`, error);
                throw error;
            })
        );
    }

    bookCarWashSimulation(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('car-wash', summaryData, userInfo, true);
    }
    bookMechanicalInspectionSimulation(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('mechanical-inspection', summaryData, userInfo, true);
    }
    bookRefuelingSimulation(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('refueling', summaryData, userInfo, true);
    }
    bookTowTruckSimulation(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('tow-truck', summaryData, userInfo, true);
    }
    bookValetSimulation(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('valet', summaryData, userInfo, true);
    }
    bookTransferSimulation(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('transfer', summaryData, userInfo, true);
    }
    bookPreTechnicalInspectionSimulation(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('pre-technical-inspection', summaryData, userInfo, true);
    }

    bookTechnicalInspectionSimulation(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('technical-inspection', summaryData, userInfo, true);
    }
    bookTechinalInspectionMotSimulation(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('technical-inspection-mot', summaryData, userInfo, true);
    }

    bookLongDistanceSimulation(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('transfer-long-distance', summaryData, userInfo, true);
    }
    bookPreTechnicalInspection(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('pre-technical-inspection', summaryData, userInfo);
    }

    bookTechnicalInspection(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('technical-inspection', summaryData, userInfo);
    }

    bookTransfer(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('transfer', summaryData, userInfo);
    }

    bookLongDistance(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('transfer-long-distance', summaryData, userInfo);
    }

    bookCarWash(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('car-wash', summaryData, userInfo);
    }

    bookMechanicalInspection(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('mechanical-inspection', summaryData, userInfo);
    }

    bookRefueling(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('refueling', summaryData, userInfo);
    }

    bookTowTruck(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.bookService('tow-truck', summaryData, userInfo);
    }

    bookValet(summaryData: SummaryData, userInfo: string): Observable<any> {
        return this.getHeaders().pipe(
            switchMap((headers) => {
                const dto = this.dtoBookValetRequest(summaryData, userInfo);
                return this.http.post(`${this.productsUrl}/business/products/valet`, dto, {
                    headers,
                });
            }),
            catchError((error) => {
                console.error('Error booking valet:', error);
                throw error;
            })
        );
    }

    bookWhateverYouWant(summaryData: SummaryData, userId: string, userEmail: string): Observable<any> {
        return this.getHeaders().pipe(
            switchMap((headers) => {
                const dto = this.dtoOpenForm(summaryData, userId, userEmail);
                return this.http.post(`${this.productsUrl}/business/products/open-form`, dto, {
                    headers,
                });
            }),
            catchError((error) => {
                console.error('Error booking custom service:', error);
                throw error;
            })
        );
    }

    getReplacementVehicleAvailabilities(zoneId: string, date: Date, carType: string): Observable<any> {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.get(`${this.productsUrl}/business/products/replacement-vehicle`, {
                    params: {
                        ZoneId: zoneId,
                        Date: date.toISOString(),
                        CarType: carType,
                    },
                    headers,
                })
            ),
            catchError((error) => {
                console.error('Error getting replacement vehicle availabilities:', error);
                throw error;
            })
        );
    }

    getInsuranceOptions(zoneId: string): Observable<any> {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.get(`${this.productsUrl}/business/products/insurance`, {
                    params: {
                        ZoneId: zoneId,
                    },
                    headers,
                })
            ),
            catchError((error) => {
                console.error('Error getting insurance price:', error);
                throw error;
            })
        );
    }

    finalBook(orderHash: string): Observable<any> {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.post(
                    `${this.productsUrl}/business/order`,
                    { orderHash },
                    {
                        headers,
                        observe: 'response',
                    }
                )
            ),
            catchError((error) => {
                console.error('Error finalizing booking:', error);
                throw error;
            })
        );
    }

    servicesMap() {
        return this.getHeaders().pipe(
            switchMap((headers) => this.http.get(`${this.productsUrl}/fleet-manager/services/map`, { headers })),
            catchError((error) => {
                console.error('Error getting services map:', error);
                throw error;
            })
        );
    }

    servicesList() {
        return this.getHeaders().pipe(
            switchMap((headers) => this.http.get(`${this.productsUrl}/fleet-manager/services/list`, { headers })),
            catchError((error) => {
                console.error('Error getting services list:', error);
                throw error;
            })
        );
    }

    validatePromotionCode(promotionCode: string, orderHash: string) {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.patch(
                    `${this.productsUrl}/business/order/${orderHash}/coupon`,
                    { CouponCode: promotionCode },
                    { headers }
                )
            ),
            catchError((error) => {
                console.error('Error validating promotion code:', error);
                throw error;
            })
        );
    }

    deletePromotionCode(orderHash: string) {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.delete(`${this.productsUrl}/business/order/${orderHash}/coupon`, { headers })
            ),
            catchError((error) => {
                console.error('Error deleting promotion code:', error);
                throw error;
            })
        );
    }

    dtoOpenForm(summaryData: SummaryData, userId: string, userInfo: string): BookOpenForm {
        const formattedServiceStartDate = this.formatDate(summaryData?.locationInfo?.serviceStartDate as string);
        const formattedServiceEndDate = this.formatDate(summaryData?.locationInfo?.serviceEndDate as string);
        const dto = {
            ZoneId: summaryData?.productInformation?.ZoneId || '',
            FinishZoneId: summaryData?.productInformation?.FinishZoneId || '',
            ClientId: userId,
            ServiceDescription: summaryData?.locationInfo?.comments ?? '',
            ContactName: summaryData?.locationInfo?.originContactName ?? '',
            ContactPhone: summaryData?.locationInfo?.originContactPhoneNumber ?? '',
            ContactEmailAddress: userInfo,
            PickupRangeStart: formattedServiceStartDate,
            PickupRangeEnd: formattedServiceEndDate,
        };
        return dto;
    }

    dtoBookRequest(summaryData: SummaryData, userEmail: string): BookRequest {
        const serviceConfiguration = this.handleServiceConfiguration(
            summaryData.productInformation?.product?.ProductKey,
            summaryData.serviceConfiguration
        );

        const dto = {
            ZoneId: summaryData?.productInformation?.ZoneId || '',
            FinishZoneId: summaryData?.productInformation?.FinishZoneId || '',
            ProductId: summaryData?.productInformation?.product?.productId || '',
            ServiceStartDate: summaryData?.locationInfo?.serviceStartDate as string,
            ServiceEndDate: summaryData?.locationInfo?.serviceEndDate as string,
            Comments: this.handleComment(summaryData) ?? '',
            OriginContactName: summaryData?.locationInfo?.originContactName ?? '',
            OriginContactPhoneNumber: summaryData?.locationInfo?.originContactPhoneNumber ?? '',
            OriginContactEmailAddress: userEmail,
            IsOriginContactExternal: summaryData?.locationInfo?.isOriginContactExternal ?? false,
            OriginAddress: summaryData?.locationInfo?.originAddress ?? '',
            OriginAddressDetails: summaryData?.locationInfo?.originAddressDetails ?? '',
            OriginAddressLatitude: summaryData?.locationInfo?.originAddressLatitude ?? 0,
            OriginAddressLongitude: summaryData?.locationInfo?.originAddressLongitude ?? 0,
            DestinationContactName: summaryData?.locationInfo?.destinationContactName ?? '',
            DestinationContactPhoneNumber: summaryData?.locationInfo?.destinationContactPhoneNumber ?? '',
            DestinationContactEmailAddress: userEmail,
            IsDestinationContactExternal: summaryData?.locationInfo?.isDestinationContactExternal ?? false,
            DestinationAddress: summaryData?.locationInfo?.destinationAddress ?? '',
            DestinationAddressDetails: summaryData?.locationInfo?.destinationAddressDetails ?? '',
            DestinationAddressLatitude: summaryData?.locationInfo?.destinationAddressLatitude ?? 0,
            DestinationAddressLongitude: summaryData?.locationInfo?.destinationAddressLongitude ?? 0,
            VehicleChassisType: summaryData?.vehicleInformation?.chassisTypeId ?? 0,
            VehicleFuelType: summaryData?.vehicleInformation?.fuelTypeId ?? 0,
            LicensePlate: summaryData?.vehicleInformation?.licensePlate ?? '',
            VehicleBrand: summaryData?.vehicleInformation?.brand ?? '',
            VehicleModel: summaryData?.vehicleInformation?.model ?? '',
            ServiceConfiguration: serviceConfiguration ?? {},
            RequiresTechnicalInspection: serviceConfiguration.RequiresTechnicalInspection ?? false,
        };
        return dto;
    }

    dtoBookValetRequest(summaryData: SummaryData, userEmail: string): BookRequest {
        const serviceConfiguration = this.handleServiceConfiguration(
            summaryData.productInformation?.product?.ProductKey,
            summaryData.serviceConfiguration
        );

        const dto = {
            ZoneId: summaryData?.productInformation?.ZoneId || '',
            FinishZoneId: summaryData?.productInformation?.FinishZoneId || '',
            ProductId: summaryData?.productInformation?.product?.productId || '',
            ServiceStartDate: summaryData?.locationInfo?.serviceStartDate as string,
            ServiceEndDate: summaryData?.locationInfo?.serviceReturnStartDate as string,
            Comments: summaryData?.locationInfo?.comments ?? '',
            OriginContactName: summaryData?.locationInfo?.originContactName ?? '',
            OriginContactPhoneNumber: summaryData?.locationInfo?.originContactPhoneNumber ?? '',
            OriginContactEmailAddress: userEmail,
            IsOriginContactExternal: summaryData?.locationInfo?.isOriginContactExternal ?? false,
            OriginAddress: summaryData?.locationInfo?.originAddress ?? '',
            OriginAddressDetails: userEmail,
            OriginAddressLatitude: summaryData?.locationInfo?.originAddressLatitude ?? 0,
            OriginAddressLongitude: summaryData?.locationInfo?.originAddressLongitude ?? 0,
            DestinationContactName: summaryData?.locationInfo?.destinationContactName ?? '',
            DestinationContactPhoneNumber: summaryData?.locationInfo?.destinationContactPhoneNumber ?? '',
            DestinationContactEmailAddress: userEmail,
            IsDestinationContactExternal: summaryData?.locationInfo?.isDestinationContactExternal ?? false,
            DestinationAddress: summaryData?.locationInfo?.destinationAddress ?? '',
            DestinationAddressDetails: '',
            DestinationAddressLatitude: summaryData?.locationInfo?.destinationAddressLatitude ?? 0,
            DestinationAddressLongitude: summaryData?.locationInfo?.destinationAddressLongitude ?? 0,
            VehicleChassisType: summaryData?.vehicleInformation?.chassisTypeId ?? 0,
            VehicleFuelType: summaryData?.vehicleInformation?.fuelTypeId ?? 0,
            LicensePlate: summaryData?.vehicleInformation?.licensePlate ?? '',
            VehicleBrand: summaryData?.vehicleInformation?.brand ?? '',
            VehicleModel: summaryData?.vehicleInformation?.model ?? '',
            ServiceConfiguration: serviceConfiguration ?? {},
            RequiresPreTechnicalInspection: false,
        };
        return dto;
    }

    dtoSimulation(summaryData: SummaryData) {
        const serviceConfiguration = this.handleServiceConfiguration(
            summaryData.productInformation.product?.ProductKey,
            summaryData.serviceConfiguration
        );
        return {
            ZoneId: summaryData?.productInformation?.ZoneId || '',
            FinishZoneId: summaryData?.productInformation?.FinishZoneId || '',
            ProductId: summaryData?.productInformation?.product?.productId || '',
            OriginAddressLatitude: summaryData?.locationInfo?.originAddressLatitude || 0,
            OriginAddressLongitude: summaryData?.locationInfo?.originAddressLongitude || 0,
            DestinationAddressLatitude: summaryData?.locationInfo?.destinationAddressLatitude || 0,
            DestinationAddressLongitude: summaryData?.locationInfo?.destinationAddressLongitude || 0,
            VehicleChassisType: summaryData?.vehicleInformation?.chassisTypeId || 0,
            VehicleFuelType: summaryData?.vehicleInformation?.fuelTypeId || 0,
            ServiceConfiguration: serviceConfiguration || {},
            ServiceStartDate: summaryData?.locationInfo?.serviceStartDate || undefined,
            ServiceEndDate: summaryData?.locationInfo?.serviceEndDate || undefined,
        };
    }

    handleServiceConfiguration(productKey?: string, serviceConfiguration?: ServiceConfiguration): any {
        if (!serviceConfiguration) return {};
        switch (productKey) {
            case PersonalizationId.MotHomologation:
                return {
                    hasCompletedRegularInspections: serviceConfiguration?.hasCompletedRegularInspections ?? false,
                    areDocumentsAvailable: serviceConfiguration?.areDocumentsAvailable,
                    reformType: serviceConfiguration?.reformType,
                };
            case PersonalizationId.Refuel:
                return {
                    MonetaryAmount: serviceConfiguration?.monetaryAmount,
                    FuelType: serviceConfiguration?.fuelType,
                };
            case PersonalizationId.Itv:
            case PersonalizationId.ItvMotorcycle:
            case PersonalizationId.ItvccMotorcycle:
                if (!serviceConfiguration?.hasAppointment) return {};
                return {
                    appointmentTime: serviceConfiguration?.appointmentTime,
                    appointmentCode: serviceConfiguration?.appointmentCode,
                    stationId: serviceConfiguration?.stationId,
                    IsInspectionPaid: serviceConfiguration?.IsInspectionPaid,
                };
            case PersonalizationId.TowTruck:
                return {
                    vehicleMovementStatus: serviceConfiguration?.vehicleMovementStatus,
                };
            case PersonalizationId.Valet:
                return {
                    stationId: serviceConfiguration?.stationId,
                };
        }
        return serviceConfiguration;
    }

    formatPickupRange(originDate: string, time: string): string {
        const date = new Date(originDate);
        const [hours, minutes] = time.split(':').map(Number);

        date.setHours(hours, minutes, 0, 0);

        const timezoneOffset = 2 * 60; // Offset for +02:00 in minutes
        const timezoneOffsetHours = Math.floor(timezoneOffset / 60);
        const timezoneOffsetMinutes = timezoneOffset % 60;

        const sign = timezoneOffset >= 0 ? '+' : '-';
        const pad = (n: number) => n.toString().padStart(2, '0');
        const timezone = `${sign}${pad(Math.abs(timezoneOffsetHours))}`;

        return `${date.toISOString().split('.')[0]}${timezone} Europe/Madrid`;
    }
    formatDate(date: string): string {
        return (
            moment(date)
                .tz('Europe/Madrid')
                .format('YYYY-MM-DDTHH:mm:ssZ') // Cambiamos ZZ por Z
                .replace('+0200', '+02') + // Ajustamos el formato del offset
            ' Europe/Madrid'
        );
    }

    handleComment(summaryData: SummaryData): string {
        if (summaryData.locationInfo?.isId3Service) {
            return '[ID3-ID4]' + summaryData.locationInfo?.comments;
        }
        return summaryData.locationInfo?.comments ?? '';
    }
}
