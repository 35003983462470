<div
    class="modal fade"
    id="modalPromotionCode"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalTitleId"
    aria-hidden="true"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="contenido-modal">
                <div class="w-100 d-flex justify-content-end">
                    <button
                        #closeModal
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="title-modal">
                    <h3>{{ 'promo_code_title' | translate }}</h3>
                    <span>{{ 'promo_code_description' | translate }}</span>
                </div>
                <div class="validation-modal">
                    <div>
                        <label for="promotionCode">{{ 'promoCodeLabel' | translate }}</label>
                        <input
                            type="text"
                            class="form-control"
                            [ngClass]="{ error: promotionCodeValidator }"
                            placeholder="{{ 'promoCodePlaceholder' | translate }}"
                            [ngModel]="promotionCode"
                            (ngModelChange)="onPromotionCodeChange($event)"
                            id="promotionCode"
                        />
                        <span class="error-span" *ngIf="promotionCodeValidator">
                            {{ 'promoCodeError' | translate }}
                        </span>
                    </div>
                    <button (click)="validatePromotionCode()" [ngClass]="{ 'error-button': promotionCodeValidator }">
                        {{ 'promoCodeButton' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
