<div class="modal-container">
    <div class="modal-exit">
        <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
    </div>
    <div class="d-flex d-row">
        <div class="modal-title-subtitle">
            <div class="modal-title">
                <span>{{ 'neumaticos' | translate }}: {{ tyre.description }}</span>
            </div>
            <div class="modal-subtitle">
                {{ tyre.short_description }}
            </div>
            <div class="d-flex d-row gap-25">
                <div class="season-label">
                    <div *ngIf="tyre.season === 'WINTER'">
                        <img src="/assets/icons/season-tag-winter.svg" [alt]="tyre.season" />
                        <span>{{ 'summer' | translate }}</span>
                    </div>
                    <div *ngIf="tyre.season === 'SUMMER'">
                        <img src="/assets/icons/season-tag-summer.svg" [alt]="tyre.season" />
                        <span>{{ 'winter' | translate }}</span>
                    </div>
                    <div *ngIf="tyre.season === 'ALL'">
                        <img src="/assets/icons/season-tag-all.svg" [alt]="tyre.season" />
                        <span>{{ 'all_v2' | translate }}</span>
                    </div>
                </div>
                <div *ngIf="tyre.stock > 0" class="with-stock">
                    <img src="/assets/icons/badge-check.svg" alt="disponible" />
                    <span>{{ 'En stock' | translate }}</span>
                </div>
                <div *ngIf="tyre.stock === 0" class="no-stock">
                    <img src="/assets/icons/ban.svg" alt="sin stock" />
                    <span>{{ 'Sin stock' | translate }}</span>
                </div>
            </div>
            <div class="d-flex d-row gap-25 category-icons">
                <div class="d-flex d-row gap-1 align-items-center" *ngIf="tyre.grip">
                    <img src="/assets/icons/rain.svg" alt="Agarre en mojado" />
                    <img
                        [src]="'/assets/icons/wet-grip-class-' + tyre.grip + '.svg'"
                        [alt]="'Agarre en lluvia ' + tyre.grip"
                    />
                </div>
                <div class="d-flex d-row gap-1 align-items-center" *ngIf="tyre.endurance">
                    <img src="/assets/icons/gas.svg" alt="" />
                    <img
                        [src]="'/assets/icons/fuel-efficiency-class-' + tyre.endurance + '.svg'"
                        [alt]="'Agarre en lluvia ' + tyre.endurance"
                    />
                </div>
                <div class="d-flex d-row gap-1 align-items-center">
                    <img src="/assets/icons/noise.svg" alt="Índice de ruido" *ngIf="tyre.noise_level_outside_db" />
                    <span *ngIf="tyre.noise_level_outside_db"
                        ><span>{{ tyre.noise_level_outside_db }}dB</span></span
                    >
                    <img
                        *ngIf="tyre.noise_level_category"
                        [src]="'/assets/icons/external-noise-class-' + tyre.noise_level_category + '.svg'"
                        [alt]="'Agarre en lluvia ' + tyre.noise_level_category"
                    />
                </div>
            </div>
            <div>
                <span class="tyre_ean">{{ 'tyre_cod_ean' | translate }}:</span> {{ tyre.ean }}
            </div>
        </div>
        <div class="tyre-image">
            <img [src]="tyre.image_url" alt="imagen rueda" />
        </div>
    </div>
    <div class="section-title">{{ 'technical_details' | translate }}</div>
    <div class="info-content">
        <div class="modal-info">
            <div>{{ 'tyre_item_code' | translate }}: {{ tyre.codigo }}</div>
            <div>{{ 'tyre_cap' | translate }}: {{ tyre.cap }}</div>
            <div>{{ 'tyre_width' | translate }}: {{ tyre.width }}</div>
            <div>{{ 'tyre_height' | translate }}: {{ tyre.height }}</div>
            <div>{{ 'tyre_diameter_with_letter' | translate }}: {{ tyre.diameter_with_letter }}</div>
            <div>{{ 'tyre_diameter_without_letter' | translate }}: {{ tyre.diameter_without_letter }}</div>
            <div>{{ 'tyre_load_index' | translate }}: {{ tyre.load_index }}</div>
            <div>{{ 'tyre_speed_index' | translate }}: {{ tyre.speed_index }}</div>
            <div>{{ 'tyre_rolling_resistance' | translate }}: {{ tyre.endurance }}</div>
            <div>{{ 'tyre_wet_grip' | translate }}: {{ tyre.grip }}</div>
            <div>{{ 'tyre_noise_index_category' | translate }}: {{ tyre.noise_level_category }}</div>
            <div>{{ 'tyre_noise_outside_index_db' | translate }}: {{ tyre.noise_level_outside_db }}dB</div>
        </div>
        <div class="vertical-hr"></div>
        <div class="modal-info">
            <div>{{ 'tyre_ice_grip' | translate }}: {{ tyre.grip_ice }}</div>
            <div>{{ 'tyre_mud_snow' | translate }}: {{ tyre.mud_snow }}</div>
            <div>{{ 'tyre_3pfsf_snow' | translate }}: {{ tyre.pfsf_snow }}</div>
            <div>{{ 'tyre_extraload' | translate }}: {{ tyre.extraload }}</div>
            <div>{{ 'tyre_runflat' | translate }}: {{ tyre.runflat }}</div>
            <div>{{ 'tyre_position' | translate }}: {{ tyre.position }}</div>
            <div>{{ 'tyre_tube_type' | translate }}: {{ tyre.tube_type }}</div>
            <div>
                {{ 'tyre_url_tag_eu' | translate }}:
                <a *ngIf="tyre.eu_tag_url" [href]="tyre.eu_tag_url" target="_blank">{{ 'eu_tag_link' | translate }}</a>
            </div>
            <div>{{ 'tyre_ecotax_category' | translate }}: {{ tyre.eco_category }}</div>
            <div>{{ 'tyre_ecotax_import' | translate }}: {{ tyre.eco_tax }}</div>
            <div>{{ 'tyre_homologation_notes' | translate }}: {{ tyre.notes }}</div>
        </div>
    </div>
</div>
