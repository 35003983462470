<div class="step1">
    <div class="d-flex">
        <app-stepper [steps]="steps" [activeStep]="currentStep"> </app-stepper>
        <div class="content-box">
            <ng-container [ngSwitch]="this.summaryData.productInformation.product?.ProductKey">
                <app-refill
                    *ngSwitchCase="personalizationId.Refuel"
                    (changedSummaryDataEvent)="onChangeSummaryData($event)"
                    (subCategorySelected)="handleSelectedSubCategory($event)"
                    [summaryData]="summaryData"
                    [preBooking]="preBooking.bind(this)"
                ></app-refill>
                <app-change-name *ngSwitchCase="personalizationId.OwnershipChange"></app-change-name>
                <app-tow
                    *ngSwitchCase="personalizationId.TowTruck"
                    (selectionChanged)="onVehicleMovementStatus($event)"
                    (subCategorySelected)="handleSelectedSubCategory($event)"
                ></app-tow>
                <app-itv
                    *ngSwitchCase="personalizationId.TechnicalInspection"
                    (selectionChanged)="onTechnicalInspectionChange($event)"
                    (subCategorySelected)="handleSelectedSubCategory($event)"
                    (finalProductSelectedEvent)="onFinalProductSelected($event)"
                    [finalProductSelected]="finalProductSelected"
                    [summaryData]="summaryData"
                ></app-itv>
                <app-itv
                    *ngSwitchCase="personalizationId.PreMot"
                    (selectionChanged)="onTechnicalInspectionChange($event)"
                    (subCategorySelected)="handleSelectedSubCategory($event)"
                    (finalProductSelectedEvent)="onFinalProductSelected($event)"
                    [finalProductSelected]="finalProductSelected"
                    [summaryData]="summaryData"
                ></app-itv>
                <app-itv
                    *ngSwitchCase="personalizationId.MotHomologation"
                    (selectionChanged)="onTechnicalInspectionChange($event)"
                    (subCategorySelected)="handleSelectedSubCategory($event)"
                    (finalProductSelectedEvent)="onFinalProductSelected($event)"
                    [finalProductSelected]="finalProductSelected"
                    [summaryData]="summaryData"
                ></app-itv>
                <app-itv
                    *ngSwitchCase="personalizationId.Itv"
                    (selectionChanged)="onTechnicalInspectionChange($event)"
                    (subCategorySelected)="handleSelectedSubCategory($event)"
                    (finalProductSelectedEvent)="onFinalProductSelected($event)"
                    [finalProductSelected]="finalProductSelected"
                    [summaryData]="summaryData"
                ></app-itv>
                <app-itv
                    *ngSwitchCase="personalizationId.ItvccMotorcycle"
                    (selectionChanged)="onTechnicalInspectionChange($event)"
                    (subCategorySelected)="handleSelectedSubCategory($event)"
                    (finalProductSelectedEvent)="onFinalProductSelected($event)"
                    [finalProductSelected]="finalProductSelected"
                    [summaryData]="summaryData"
                ></app-itv>
                <app-itv
                    *ngSwitchCase="personalizationId.ItvMotorcycle"
                    (selectionChanged)="onTechnicalInspectionChange($event)"
                    (subCategorySelected)="handleSelectedSubCategory($event)"
                    (finalProductSelectedEvent)="onFinalProductSelected($event)"
                    [finalProductSelected]="finalProductSelected"
                    [summaryData]="summaryData"
                ></app-itv>
                <app-ask-for-everything *ngSwitchCase="personalizationId.OpenForm"></app-ask-for-everything>
            </ng-container>
            <app-new-service-personalization-template
                [summaryData]="summaryData"
                (selectedSubcategoryEvent)="handleSelectedSubCategory($event)"
                *ngIf="
                    !isNotConventionalPersonalization &&
                    this.summaryData.productInformation.product?.ProductKey !== personalizationId.PreMot &&
                    this.summaryData.productInformation.product?.ProductKey !== personalizationId.MotHomologation &&
                    this.summaryData.productInformation.product?.ProductKey !== personalizationId.ItvMotorcycle &&
                    this.summaryData.productInformation.product?.ProductKey !== personalizationId.Itv &&
                    this.summaryData.productInformation.product?.ProductKey !== personalizationId.TechnicalInspection
                "
            ></app-new-service-personalization-template>
        </div>
    </div>
</div>
