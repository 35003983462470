<div [class]="isCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded'">
    <div class="sidebar-header justify-content-between">
        <!-- <a routerLink="/">
            <img src="assets/cafler-logo.svg" alt="Logo" />
            <h5 [ngClass]="{ 'd-none': isCollapsed, 'nav-title': true }">Cafler</h5>
        </a> -->
        <div class="logo-container">
            <img class="logo" src="assets/images/cafler-transitalia.png" alt="Logo" routerLink="/" />
        </div>
        <button class="expand-nav" (click)="toggleSidebar()">
            <img src="{{ isCollapsed ? 'assets/expand-icon.svg' : 'assets/minimize-icon.svg' }}" alt="Toggle sidebar" />
        </button>
    </div>
    <div class="upper-half">
        <ul class="nav flex-column section">
            <ng-container *ngFor="let section of sections">
                <li *ngIf="section.children && section.children.length > 0" class="nav-section">
                    <div [ngClass]="{ 'd-none': isCollapsed || !section.title, 'section-title': true }">
                        {{ section.title | translate }}
                    </div>
                    <hr [ngClass]="{ 'd-none': !isCollapsed || !section.title }" />
                    <br [ngClass]="{ 'd-none': section.title }" />
                    <ul>
                        <li
                            *ngFor="let subSection of section.children"
                            class="children"
                            [ngClass]="{ active: checkSamePath(subSection.path) || checkSamePath(subSection.title) }"
                        >
                            <a
                                [routerLink]="subSection.path"
                                (click)="subSection.path === 'new-service' && clearSummaryData()"
                            >
                                <img
                                    class="subSection-icon"
                                    [src]="subSection.icon"
                                    alt="{{ subSection.title | translate }}"
                                />{{ !isCollapsed ? (subSection.title | translate) : '' }}
                                <span
                                    [ngClass]="{ 'd-none': isCollapsed }"
                                    class="isNewBadge"
                                    *ngIf="subSection.isNew"
                                    >{{ 'newBadge' | translate }}</span
                                >
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="!section.children || section.children.length === 0" class="nav-item">
                    <a [routerLink]="section.path">
                        <img [src]="section.icon" alt="{{ section.title | translate }}" />{{
                            section.title | translate
                        }}
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>

    <div class="lower-half">
        <ul class="nav flex-column sidebar-footer">
            <li class="nav-section">
                <hr [ngClass]="{ 'd-none': !isCollapsed }" />
                <br />
                <ul>
                    <li>
                        <button type="button" data-bs-toggle="modal" data-bs-target="#supportModal">
                            <img
                                class="subSection-icon"
                                src="../../assets/icons/navbar/nav-support.svg"
                                alt="Centro de ayuda"
                            />
                            <span>{{ !isCollapsed ? ('contactSupport' | translate) : '' }}</span>
                        </button>
                    </li>
                    <li>
                        <a
                            href="https://cafler.atlassian.net/wiki/external/ZjI5MWFlZDM2NGIyNDlhODkyNTM0NDFiODE0NmQ2MjA"
                            target="_blank"
                        >
                            <img
                                class="subSection-icon"
                                src="../../assets/icons/navbar/nav-faq.svg"
                                alt="Centro de ayuda"
                            />
                            <span>{{ !isCollapsed ? ('helpCenter' | translate) : '' }}</span>
                            <span [ngClass]="{ 'd-none': isCollapsed }">
                                <img
                                    class="subSection-icon-help"
                                    src="../../assets/icons/navbar/nav-external-link.svg"
                                    alt="Link Externo"
                                />
                            </span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/logout']">
                            <img
                                class="subSection-icon"
                                src="../../assets/icons/navbar/nav-logout.svg"
                                alt="Cerrar sesión"
                            />
                            <span class="end-session-text">{{ !isCollapsed ? ('logout' | translate) : '' }}</span>
                        </a>
                    </li>
                </ul>
            </li>
            <hr />
        </ul>
        <div class="user-info">
            <img [src]="userImg" class="user-img" alt="User Image" />
            <div class="user-details" [ngClass]="{ 'd-none': isCollapsed, 'user-details': true }">
                <span class="user-name">{{ userName }}</span>
                <span class="user-email">{{ userEmail }}</span>
            </div>
        </div>
    </div>
</div>
<div
    class="modal fade"
    id="supportModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="supportModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="contact-modal">
            <div class="close-box">
                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                    <img src="../../../../../assets/icons/close-modal.svg" alt="" />
                </button>
            </div>
            <div class="content">
                <div class="contact-box">
                    <h5>
                        <img src="../../../../../assets/icons/phone-outgoing.svg" alt="" />{{ 'contactUs' | translate }}
                    </h5>
                    <p>{{ 'callUs' | translate }}</p>
                </div>
                <p class="phone-box">
                    <span class="title">{{ 'customerServiceNumber' | translate }}</span>
                    <span class="phone">+34 900 649 071</span>
                    <span class="title">{{ 'email_address' | translate }}:</span>
                    <span class="phone">soporte&#64;cafler.com</span>
                    <span class="date">{{ 'workingHours' | translate }}</span>
                </p>
            </div>
        </div>
    </div>
</div>
