import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from './datatable/datatable.component';
import { MapComponent } from './map/map.component';
import { PlateNumberComponent } from './plate-number/plate-number.component';
import { StatusComponent } from './status/status.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { ServiceIconsComponent } from './service-icons/service-icons.component';
import { StepperComponent } from './stepper/stepper.component';
import { BillingSummaryComponent } from './billing-summary/billing-summary.component';
import { HttpClientModule } from '@angular/common/http';
import { HomeService } from '../pages/home/services/home.service';
import { LogoutComponent } from './logout/logout.component';
import { ToggleComponent } from './toggle/toggle/toggle.component';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerDatatableComponent } from './spinner-datatable/spinner-datatable.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GanttComponent } from './gantt/gantt.component';
import { CancelModalComponent } from './datatable/components/cancel-modal/cancel-modal.component';
import { VehicleStatusComponent } from './vehicle-status/vehicle-status.component';
import { AlertComponent } from './alert/alert.component';
import { AddressBoxComponent } from './billing-summary/components/address-box/address-box.component';
import { AdditionalInfoComponent } from './billing-summary/components/additional-info/additional-info.component';
import { CommentsComponent } from './billing-summary/components/comments/comments.component';
import { PricingSummaryComponent } from './billing-summary/components/pricing-summary/pricing-summary.component';
import { CancellationPolicyComponent } from './billing-summary/components/cancellation-policy/cancellation-policy.component';
import { CheckoutContainerComponent } from './billing-summary/components/checkout-container/checkout-container.component';
import { ModalPromotionCodeComponent } from './billing-summary/modals/modal-promotion-code/modal-promotion-code.component';
import { ModalCancellationPolicyComponent } from './billing-summary/modals/modal-cancellation-policy/modal-cancellation-policy.component';
import { ModalRefuelingFeeComponent } from './billing-summary/modals/modal-refueling-fee/modal-refueling-fee.component';
import { ModalMinimumConditionsComponent } from './billing-summary/modals/modal-minimum-conditions/modal-minimum-conditions.component';
import { ContactInfoComponent } from './billing-summary/components/contact-info/contact-info.component';
import { ConfirmDialogComponent } from './confirm-modal/confirm-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FunnelDialogComponent } from './funnel-dialog/funnel-dialog.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ExtrasFunnelDialogComponent } from './funnel-dialog/extras-funnel-dialog/extras-funnel-dialog.component';
import { SummaryFunnelDialogComponent } from './funnel-dialog/summary-funnel-dialog/summary-funnel-dialog.component';

@NgModule({
    declarations: [
        DatatableComponent,
        HeaderComponent,
        MapComponent,
        NavbarComponent,
        PlateNumberComponent,
        StatusComponent,
        ServiceIconsComponent,
        StepperComponent,
        BillingSummaryComponent,
        LogoutComponent,
        ToggleComponent,
        SpinnerDatatableComponent,
        InfoModalComponent,
        FunnelDialogComponent,
        GanttComponent,
        CancelModalComponent,
        VehicleStatusComponent,
        AlertComponent,
        AddressBoxComponent,
        AdditionalInfoComponent,
        CommentsComponent,
        PricingSummaryComponent,
        CancellationPolicyComponent,
        CheckoutContainerComponent,
        ModalMinimumConditionsComponent,
        ModalPromotionCodeComponent,
        ModalCancellationPolicyComponent,
        ModalRefuelingFeeComponent,
        ModalMinimumConditionsComponent,
        ContactInfoComponent,
        ConfirmDialogComponent,
        FunnelDialogComponent,
        ExtrasFunnelDialogComponent,
        SummaryFunnelDialogComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        HttpClientModule,
        TranslateModule.forChild(),
        ModalModule.forRoot(),
        MatDialogModule,
        MatSlideToggleModule,
    ],
    exports: [
        DatatableComponent,
        HeaderComponent,
        MapComponent,
        NavbarComponent,
        PlateNumberComponent,
        StatusComponent,
        ServiceIconsComponent,
        StepperComponent,
        BillingSummaryComponent,
        ToggleComponent,
        SpinnerDatatableComponent,
        GanttComponent,
        VehicleStatusComponent,
        AlertComponent,
        ConfirmDialogComponent,
    ],
    providers: [HomeService],
})
export class SharedModule {}
