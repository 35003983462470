export const environment = {
    production: false,
    tracking_url: 'https://tracking-api.development.cafler.com/api',
    identity_url: 'https://api.development.cafler.com/dev/identity',
    products_url:'https://products-api.development.cafler.com',
    base: 'fleet-manager/',
    requested_page: '1',
    azureActiveDirectory: {
        clientId: '95d369d6-e03b-4d77-bb81-959b294718c7',
        instance: 'https://identity.cafler.com/tfp/',
        tenant: '38e0a06f-5e98-4f79-b789-9ac3f3c7638b/',
        authority:
            'https://identity.cafler.com/tfp/38e0a06f-5e98-4f79-b789-9ac3f3c7638b/B2C_1_FleetManager_Development_PasswordReset/',
        policyName: 'B2C_1_FleetsLogin/',
        redirectUri: 'https://platform.development.cafler.com/',
        postLogoutRedirectUri: 'https://platform.development.cafler.com/',
    },
    BASE_URL: 'https://inbox.fonia.ai',
    API_VERSION: '/api/v1',
    ACCOUNT_ID: '29',
    TOKEN: '9FUocCVaS91obqBXw9P97aQ3',
};
