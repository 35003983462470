import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlertService } from '../services/alert.service';

@Injectable({
    providedIn: 'root',
})
export class AlertGuard implements CanActivate {
    constructor(private alertService: AlertService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.alertService.shouldShowAlert()) {
            this.alertService.setShowAlert(true);
        } else {
            this.alertService.resetAlert();
        }
        return true;
    }
}
